import API from "../../../API/API";
const api = new API();
const endPoints = "user_apps";
export const updateCustomAppApi = async (user, userId) => {
  console.log("userId,user in api",user,typeof userId ,userId);
  return new Promise(async (resolve, reject) => {
    try {
      const result = await api.put(`${endPoints}/${userId}`, user);
      console.log("result updateCustomAppApi api", result.data);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
