import * as types from "../../actions/actionTypes";

const initialState = {
  section4: {
    data: [],
    loading: false,
    error: null,
  },
};

const landingpageSection4Reducer = (state = initialState.section4, action) => {
  switch (action.type) {
    case types.LANDINGPAGE_SECTION4_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LANDINGPAGE_SECTION4_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.LANDINGPAGE_SECTION4_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default landingpageSection4Reducer;
