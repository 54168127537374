import { Box } from '@mui/material'
import React from 'react'

const BottomNotch = () => {
  return (
    <Box
    sx={{
      position: 'absolute',
      bottom: '5px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100px',
      height: '5px',
      backgroundColor: 'black',
      borderRadius: '50px',

   
    }}
  />
  )
}

export default BottomNotch
