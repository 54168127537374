import React, { useState } from 'react';
import { Box, Button, Container, Divider, Typography, Link, Grid, TextField, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import OtpInputs from './OtpInputs';

const VerifyOtpPage = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;


    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found
    const [otp, setOtp] = useState("");

    return (
        <Grid container
            direction="column"
            sx={{
                height: '364px',
                background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
                border: '4px solid grey',
                borderRadius: '8px',
                alignItems: 'center'
            }}
        >
            <Box item sx={{ textAlign: 'center', mt: '20px' }}>

                <img
                    src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
                        ? storedAppData.logo.selectedImage
                        : "../assets/images/defaultLogo.png"}

                    style={{ width: '25px', height: '25px' }}
                />


                {/* App Name */}
                <Typography sx={{ fontSize: '12px', fontFamily: '"Inter", sans-serif', fontWeight: '500', mt: '0px' }}>
                    {storedAppData.application_name}
                </Typography>
            </Box>

            <Grid item sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, width: '252px', height: '158px', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
                <Box

                    sx={{

                        padding: '8px 54px',
                        borderRadius: '16px',
                        textAlign: 'center',
                        width: '100%',

                    }}
                >
                    <Grid item sx={{ justifyContent: 'center' }}>
                        <Box sx={{}}>
                            <Typography sx={{ fontWeight: '500', fontSize: '14px', fontFamily: '"Inter", sans-serif', }}>Verify OTP</Typography>
                            <Typography sx={{ fontSize: '6px', fontFamily: '"Inter", sans-serif', lineHeight: '14px', fontWeight: '400', mb: '3px' }}>
                                We have sent an OTP to your
                                Email address
                            </Typography>
                        </Box>


                        <OtpInputs otp={otp} setOtp={setOtp} extractedColor={lighten(extractedColor,0.5)} />

                    </Grid>
                    <Box sx={{ display: 'flex', mt: '5px' }}>
                        <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', fontWeight: '600' }}>
                            <span style={{ fontWeight: '500', fontSize: '8px', fontFamily: '"Inter", sans-serif', color: extractedColor,marginLeft:'5px' }}>
                                Resend OTP?
                            </span>

                        </Typography>
                        <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', fontWeight: '600', ml: '2px' }}>in 00:00</Typography>
                    </Box>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            mb: 1,
                            backgroundColor: `${extractedColor}`,
                            '&:hover': { backgroundColor: `${extractedColor}` },
                            textTransform: 'none',
                            color: '#4B5563',
                            fontWeight: '600',
                            fontSize: '8px',
                            fontFamily: '"Inter", sans-serif',
                            borderRadius: '4px',
                            mt: '10px'
                        }}
                    >
                        Verify OTP
                    </Button>
                </Box>
            </Grid>

        </Grid>
    )
}

export default VerifyOtpPage
