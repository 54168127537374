import React, { useState } from 'react'
import { Box, Button, Grid, lighten, TextField, Typography } from '@mui/material';
import { Icon } from "@iconify/react";
import SignUpOtpVerify from '../../Login&Signup/SignUpOtpVerify';
import ForgetPasswordOtpInputs from '../../Login&Signup/ForgetPasswordOtpInputs';
import OtpInputs from './OtpInputs';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const VerifyOtp = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;


    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found

    console.log("Extracted Color:", extractedColor);
    const [otp, setOtp] = useState("");
    return (
        <>

            <Grid container

                sx={{
                    height: '640px',
                    background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
                    border: '10px solid grey',
                    borderRadius: '20px',
                    width: '300px',
                    padding: '7px',
                    position: 'relative',
                    alignItems:'center'


                }}
            >
                  <TopNotch/>
                  <Box sx={{ top: '40px', left: '10px',position:'absolute' }}>
                    <Icon icon="line-md:arrow-left" width="20px" height="20px" style={{ color: '#14042C' }} />
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '20px',
                        width: '100%',
                        mb: '90px'

                    }}
                >
                    {/* App Logo */}
                    <Box >
                        <img
                            src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
                                ? storedAppData.logo.selectedImage
                                : "../assets/images/defaultLogo.png"}

                            style={{ width: '64px', height: '64px' }}
                        />
                    </Box>

                    {/* App Name */}
                    <Typography variant="h5" sx={{ mb: 4, fontFamily: '"Inter", sans-serif', }}>
                        {storedAppData.application_name}
                    </Typography>

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            border: `1px solid ${lighten(extractedColor, 0.5)}`,
                            padding: '10px',
                            borderRadius: '16px',
                            textAlign: 'center',
                            width: '100%',
                            ml: '1px',

                        }}
                    >
                        <Grid item sx={{ justifyContent: 'center' }}>
                            <Box sx={{ mb: '8px' }}>
                                <Typography sx={{ fontWeight: '500', fontSize: '18px', fontFamily: '"Inter", sans-serif', }}>Verify OTP</Typography>
                                <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', lineHeight: '14px' }}>
                                    We have sent an OTP to your
                                    Email address
                                </Typography>
                            </Box>


                            <OtpInputs otp={otp} setOtp={setOtp} extractedColor={lighten(extractedColor, 0.5)} />

                        </Grid>
                        <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', fontWeight: '600', mt: '5px', justifyContent: 'flex-start' }}>
                            <span style={{ fontWeight: '500', fontSize: '12px', fontFamily: '"Inter", sans-serif', marginRight: '5px' }}>
                                Resend OTP?
                            </span>
                            in 00:00
                        </Typography>

                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                mb: 1,
                                backgroundColor: `${lighten(extractedColor, 0.5)}`,
                                '&:hover': { backgroundColor: `${lighten(extractedColor, 0.5)}` },
                                textTransform: 'none',
                                color: '#4B5563',
                                fontWeight: '600',
                                fontSize: '12px',
                                fontFamily: '"Inter", sans-serif',
                                borderRadius: '8px',
                                mt: '10px'
                            }}
                        >
                            Verify OTP
                        </Button>
                    </Grid>
                </Box>
               <BottomNotch/>
            </Grid>
        </>
    )
}

export default VerifyOtp
