import * as types from "../actionTypes";
import { updateCustomAppApi } from "../../apis/stepsFlow/updateCustomAppApi";
export const updateCustomAppStart = (user, userId) => ({
  type: types.UPDATE_CUSTOMAPP_START,
  payload: [user, userId],
});
export const updateCustomAppSuccess = () => ({
  type: types.UPDATE_CUSTOMAPP_SUCCESS,
});
export const updateCustomAppError = (error) => ({
  type: types.UPDATE_CUSTOMAPP_ERROR,
  payload: error,
});
export const updateCustomAppInitiate = (user,userId, navigate) => {
  
  return function (dispatch) {
    dispatch(updateCustomAppStart(user,userId));
   
    updateCustomAppApi(user, userId)
      .then((res) => {
        // let userData = res?.data;
        console.log("res", res);
        console.log("res.id", res?.data?.id);
        dispatch(updateCustomAppSuccess(res));
        if (res.status === 200) {
          navigate("/appfeature" ,{state:res?.data?.id});
          localStorage.setItem("appId", res?.data?.id);

          // Retrieve and log the appId from localStorage
          console.log("appId", localStorage.getItem("appId"));
        }
      })
      .catch((error) => dispatch(updateCustomAppError(error.message)));
  };
};
