import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, Avatar, ButtonBase, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const Profile = (data) => {
  const storedAppData = data.data;
  const colorGradient = storedAppData.theme_color;



  const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
  const extractedColor = colorMatch ? colorMatch[0] : '#000';

  const options = [
    { label: 'Orders' },
    { label: 'Saved Payments' },
    { label: 'Wallet' },
    { label: 'Reviews' },
    { label: 'FAQ' },
    { label: 'Help & Support' },
    { label: 'Settings' },
  ];
  return (
    <Grid container
      sx={{
        height: '710px',
        border: '10px solid grey',
        borderRadius: '20px',
        width: '300px',
        position:'relative'


      }}
    >

     <TopNotch/>
      <Grid container item sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mt: '22px',
        padding: '15px 6px 0px 6px',
        boxShadow: `0px 4px 4px -2px ${lighten(extractedColor, 0.7)}`,
        height: '48px',
      }}>
        <Icon icon="gg:menu" width="24px" height="24px" style={{ color: "#000000", }} />

        <Typography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '18px', ml: '5px', fontWeight: '500', }}>
          Wishlist
        </Typography>

        <Icon icon="mdi:cart" width="24px" height="24px" style={{ color: '#000000', }} />
      </Grid>
      <Box sx={{ padding: '5px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            border: `1px solid ${lighten(extractedColor,0.5)}`,
            width: '100%',
            borderRadius: '16px',
            padding: '5px'


          }}
        >
          <Avatar
            alt="Jorge Schinner"
            src=" ./assets/images/ProfileImage.jpeg" 
            sx={{ width: 56, height: 56, marginRight: 2 }}
          />
          <Box>
            <Typography variant="h6" component="div">
              Jorge Schinner
            </Typography>
            <Typography variant="body2" color="text.secondary">
              +1 9876541230
            </Typography>
            <Typography variant="body2" color="text.secondary">
              jorgeschinner@example.com
            </Typography>
          </Box>


          <IconButton
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px'

            }}
          >
            <Icon icon="material-symbols:edit" width="12px" height="12px" style={{ color: '#000000' }} />
          </IconButton>
        </Box>
        <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, width: '100%', borderRadius: '16px', padding: '5px 5px', mt: '5px' }}>
          <Typography sx={{ mt: '5px', fontFamily: '"Inter", sans-serif', }}>My addresses</Typography>
          <Box sx={{ padding: '0px 3px' }}>
            <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '16px', padding: '8px', mt: '3px' }} >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>Jorge Schinner</Typography>
                <Typography sx={{ fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>(+1 9876541230)</Typography>

                <Box sx={{ backgroundColor: `${lighten(extractedColor, 0.5)}`, borderRadius: '8px', padding: '3px 8px' }} >
                  <Typography sx={{ fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif', }}>Home</Typography>
                </Box>
                <MoreVertIcon fontSize="10px" />

              </Box>
              <Typography sx={{ fontSize: '10px', padding: '3px 5px', fontFamily: '"Inter", sans-serif', }}> 12345 West Elm Street, Suite 9876, Floor 12, Downtown Business Center,
                Landmark Tower, Metropolis City, District 5, New York, NY 10001-5678,
                USA</Typography>


            </Box>
            <ReusableButton
              sx={{
                width: '100%',
                borderRadius: '8px',
                backgroundColor: `${lighten(extractedColor, 0.5)}`,
                color: '#000000',
                mt: '5px',
                fontFamily: '"Inter", sans-serif',
                fontSize:'12px',
                ':hover':{color:'black',backgroundColor:`${lighten(extractedColor, 0.5)}`}

              }}
            >
              Add new address
            </ReusableButton>
          </Box>

        </Box>
        <Grid container item spacing={1} sx={{ mt: '5px' }}>
          {options.map((option, index) => (
            <Grid item xs={6} key={index}>
              <ButtonBase
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',

                  borderRadius: '8px',
                  border: `1px solid ${lighten(extractedColor,0.5)}`,
                  boxShadow: 1,
                  backgroundColor: '#fff',
                  textAlign: 'left',
                }}
              >
                <Typography sx={{ fontSize: '11px', ml: '3px', fontWeight: '500', fontFamily: '"Inter", sans-serif', }}>{option.label}</Typography>
                <IconButton>
                  <Icon icon="iconamoon:arrow-right-2-light" width="22px" height="22px" style={{ color: '#000000' }} />
                </IconButton>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
        <ReusableButton
          sx={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: `${lighten(extractedColor, 0.5)}`,
            color: '#000000',
            mt: '8px',
            fontFamily: '"Inter", sans-serif',
            mb:'8px',
            fontSize:'12px',
            ':hover':{color:'black',backgroundColor:`${lighten(extractedColor, 0.5)}`}

          }}
        >
          Sign Out
        </ReusableButton>
        

      </Box>
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // Center content vertically
        padding: '4px 10px 10px 10px',
        boxShadow: `0px -4px 8px -2px ${lighten(extractedColor, 0.7)}`,
        width: '100%',
       
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Icon icon="ic:round-home" width="20px" height="20px" style={{ color:'black' }} />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14px',
            color: '#000000',
            fontFamily: '"Inter", sans-serif',
            
          }}
        >
          Home
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Icon icon="ic:baseline-category" width="20px" height="20px" style={{ color: 'black' }} />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14px',
            color: '#000000',
            fontFamily: '"Inter", sans-serif',
          
          }}
        >
          Categories
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Icon icon="mdi:heart" width="20px" height="20px" style={{ color:'black', }} />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14px',
            color: '#000000',
            fontFamily: '"Inter", sans-serif',
          
          }}
        >
          Wishlist
        </Typography>
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Icon icon="ic:round-account-circle" width="20px" height="20px" style={{ color:extractedColor,}} />
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '14px',
            color: '#000000',
            fontFamily: '"Inter", sans-serif',
          
          }}
        >
          Profile
        </Typography>
      </Box>
      </Box>
      <BottomNotch/>
    </Grid>
  )
}

export default Profile
