import React from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardContent, CardMedia, IconButton, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';
import ReusableButton from '../../../components/Button';
import TopNotch from './TopNotch';
import BottomNotch from './BottomNotch';

const Wishlist = (data) => {
  const storedAppData = data.data;
  const colorGradient = storedAppData.theme_color;



  const colorMatch = colorGradient ? colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/) : null;
  const extractedColor = colorMatch ? colorMatch[0] : '#000';
  const products = [
    {
      name: 'Apple MacBook Pro',
      price: '₹3,39,915',
      originalPrice: '₹3,99,000',
      discount: '15% off',
      image:  "./assets/images/Laptop1.png",
      rating: 3,
      liked: true,
    },
    {
      name: 'Lenovo Legion 9',
      price: '₹4,49,990',
      originalPrice: '₹6,29,890',
      discount: '28% off',
      image:  "./assets/images/Laptop2.png",
      rating: 4,
      liked: false,
    },
    {
      name: 'Apple 2020 MacBook',
      price: '₹90,891',
      originalPrice: '₹1,00,000',
      discount: '10% off',
      image:  "./assets/images/Laptop3.png",
      rating: 4,
      liked: false,
    },
    {
      name: 'MSI Titan 18HX',
      price: '₹4,09,990',
      originalPrice: '₹4,99,990',
      discount: '18% off',
      image:  "./assets/images/Laptop4.png",
      rating: 3,
      liked: true,
    },
  ];
  const products2 = [
    {
      name: 'Apple MacBook Pro',
      price: '₹3,39,915',
      originalPrice: '₹3,99,000',
      discount: '15% off',
      image:  "./assets/images/Laptop1.png",
      rating: 3,
      liked: true,
    },
    {
      name: 'Lenovo Legion 9',
      price: '₹4,49,990',
      originalPrice: '₹6,29,890',
      discount: '28% off',
      image:  "./assets/images/Laptop2.png",
      rating: 4,
      liked: false,
    },
  ];
  return (
    <Grid container
      sx={{
        height: '780px',
        border: '10px solid grey',
        borderRadius: '20px',
        width: '300px',
        position: 'relative'


      }}
    >
      <TopNotch />
     <Grid container item sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mt: '22px',
        padding: '15px 6px 0px 6px',
        boxShadow: `0px 4px 4px -2px ${lighten(extractedColor, 0.7)}`,
        height: '48px',
      }}>
        <Icon icon="gg:menu" width="24px" height="24px" style={{ color: "#000000", }} />
        <Typography sx={{ fontFamily: '"Inter", sans-serif', fontSize: '18px', ml: '5px', fontWeight: '500', }}>
          Wishlist
        </Typography>
       <Icon icon="mdi:cart" width="24px" height="24px" style={{ color: '#000000', }} />
      </Grid>
      <Box sx={{ padding: '5px' }}>
        <Grid container sx={{ mt: '0px' }}>
          {products.map((product, index) => (
            <Grid item xs={12} key={index} sx={{ mb: '8px' }}>
              <Box
                sx={{
                  display: 'flex',
                  position: 'relative',
                  padding: '8px',
                  backgroundColor: '#fff',
                  border: `1px solid ${lighten(extractedColor, 0.7)}`,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  borderRadius: '16px',
                }}
              >
                {/* Product Image */}
                <Box
                  component="img"
                  src={product.image}
                  alt={product.name}
                  sx={{ width: '90px', height: '56px',  marginTop: '10px' }}
                />


                {/* Product Details */}
                <Box sx={{ marginLeft: '8px', flexGrow: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '10px',
                      color: '#000',
                      fontFamily: '"Inter", sans-serif',
                      
                    }}
                  >
                    {product.name}
                  </Typography>
                  {/* Price Section */}
                  <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>
                      {product.price}
                    </Typography>

                    <Typography sx={{ textDecoration: 'line-through', ml: '3px', mr: '3px', color: '#9e9e9e', fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>
                      {product.originalPrice}
                    </Typography>
                    <Typography sx={{ color: extractedColor, fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>
                      {product.discount}
                    </Typography>

                  </Box>

                  {/* Rating */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: '3px' }}>
                    {Array.from({ length: Math.floor(product.rating) }).map((_, i) => (
                      <Icon
                        key={i}
                        icon="mdi:star"
                        style={{ color: extractedColor, fontSize: '12px', marginRight: '2px' }}
                      />
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ width: '112px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.7)}`, textAlign: 'center', alignItems: 'center', backgroundColor: `${lighten(extractedColor, 0.5)}` }}>
                      <Typography sx={{ fontWeight: '500', fontSize: '10px', fontFamily: '"Inter", sans-serif', }}>Move to cart</Typography>
                    </Box>
                    <Box sx={{ borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.7)}`, ml: '10px', padding: '0px 5px' }}>
                      <Icon icon="material-symbols:delete" width="12px" height="12px" style={{ color: '#000000' }} />

                    </Box>

                  </Box>


                </Box>
              </Box>

            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '0px' ,padding:'0px 3px'}}>
          <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Recently Viewed</Typography>
          <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#6B7280', fontFamily: '"Inter", sans-serif', color: `${extractedColor}` }}>View all</Typography>
        </Box>
        <Grid container spacing={1} sx={{ mt: '0px' }}>
          {products2.map((product, index) => (
            <Grid item xs={6} key={index}>
              <Card sx={{ border: ` 1px solid ${lighten(extractedColor, 0.7)}`, borderRadius: '15px', padding: '12px', position: 'relative' }}>
                <Box sx={{ position: 'absolute', top: '4px', right: '1px' }}>
                  <IconButton>
                    <Icon
                      icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                      style={{ color: `${extractedColor}`, fontSize: '20px' }}
                    />
                  </IconButton>
                </Box>
                <CardMedia
                  component="img"
                  height="90px"
                  image={product.image}
                  alt={product.name}
                  sx={{ objectFit: 'contain', mt: '5px' }}
                />

                <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                  {product.name}
                </Typography>
                <Box sx={{ mt: '3px' }}>

                  <Typography variant="body1" sx={{ color: '#000' }}>
                    {product.price}
                  </Typography>

                </Box>


              </Card>
            </Grid>
          ))}
        </Grid>

      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center', 
          padding: '3px 10px 10px 10px',
          boxShadow: `0px -4px 8px -2px ${lighten(extractedColor, 0.7)}`,
          width: '100%',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Icon icon="ic:round-home" width="20px" height="20px" style={{ color: 'black' }} />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '14px',
              color: '#000000',
              fontFamily: '"Inter", sans-serif',

            }}
          >
            Home
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Icon icon="ic:baseline-category" width="20px" height="20px" style={{ color: 'black' }} />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '14px',
              color: '#000000',
              fontFamily: '"Inter", sans-serif',

            }}
          >
            Categories
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Icon icon="mdi:heart" width="20px" height="20px" style={{ color: extractedColor, }} />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '14px',
              color: '#000000',
              fontFamily: '"Inter", sans-serif',

            }}
          >
            Wishlist
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Icon icon="ic:round-account-circle" width="20px" height="20px" style={{ color: 'black', }} />
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '14px',
              color: '#000000',
              fontFamily: '"Inter", sans-serif',

            }}
          >
            Profile
          </Typography>
        </Box>
      </Box>
      <BottomNotch/>

    </Grid>
  )
}

export default Wishlist
