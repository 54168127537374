import * as types from "../../actions/actionTypes";

// Define the initial state for Razorpay
const initialState = {
  razorpay: {
    loading: false,
    error: null,
    response: null, // To store the API response
  },
};

// Define the razorpayReducer function
const razorpayPostReducer = (state = initialState.razorpay, action) => {
  switch (action.type) {
    case types.RAZORPAY_START:
      // Start the loading state when the Razorpay process begins
      return {
        ...state,
        loading: true,
        error: null, // Clear previous errors if any
        response: null, // Clear previous response
      };
    case types.RAZORPAY_SUCCESS:
      // Handle successful Razorpay API response
      return {
        ...state,
        loading: false,
        response: action.payload, // Store the API response
      };
    case types.RAZORPAY_ERROR:
      // Handle errors that occur during the Razorpay process
      return {
        ...state,
        loading: false,
        error: action.payload, // Store the error message
      };
    default:
      return state;
  }
};

export default razorpayPostReducer;
