import * as types from ".././../actions/actionTypes"

// Define the initial state for content
const initialState = {
  content: {
    data: [],   // This will hold the fetched content data
    loading: false,
    error: null,
  },
};

// Define the contentReducer function
const fetchContentReducer = (state = initialState.content, action) => {
  switch (action.type) {
    case types.FETCH_CONTENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.FETCH_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default fetchContentReducer;
