// fetchWalletReducer.js
import * as types from "../../actions/actionTypes";

// Define the initial state for wallet
const initialState = {
  wallet: {
    data: [],   // This will hold the fetched wallet data
    loading: false,
    error: null,
  },
};

// Define the fetchWalletReducer function
const getWalletReducer = (state = initialState.wallet, action) => {
  switch (action.type) {
    case types.GET_WALLET_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.GET_WALLET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getWalletReducer;
