import React from 'react';
import { Button } from '@mui/material';
const BottomBackButton = (props) => {
  return (
    <>
      <Button
        onClick={props.onClick}
        variant={props.variant}
        sx={{
          ...props.sx,
          textTransform: "none",
          height: "2.75rem",
          borderRadius: "6.25rem",
          border: "1px solid #D1D5DB",
          backgroundColor: "#FFFFFF",
          color: "#14042C",
          fontWeight: 600,
          lineHeight: "1.125rem",
          textAlign: "center",
          fontFamily: '"Inter", sans-serif',
          boxShadow: "none",
          whiteSpace: "nowrap",
          // padding: "4px 70px",
          padding: {
            xs: "8px 16px",
            sm: "10px 24px",
            md: "12px 32px",
          },
          fontSize: {
            xs: "0.895rem", // On extra small screens, the font size adjusts with viewport width
            sm: "1rem", // On small screens, adjust accordingly
            md: "1.025rem", // On medium screens and above, use a rem value
          },
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "none",
            color: "#fff",
            backgroundColor: "#14042C",
            transform: "scale(1.04)",
          },
          "& span": {
            display: "inline-block",
            transition: "transform 0.3s ease-in-out",
          },
          "&:hover span": {
            transform: "scale(1.05)",
          },
        }}
      >
        <span> {props.children}</span>
      </Button>
    </>
  );
}

export default BottomBackButton