import React from 'react'
import { Box } from '@mui/material';
import ReusableDrawer from '../components/Drawer';
import CustomizedStepper from '../components/CustomizedStepper';
const steps = [
  {
    label: "1",
    description: "",
  },
  {
    label: "2",
    description: "",
  },
  {
    label: "3",
    description: "",
  },
  {
    label: "4",
    description: "",
  },
  {
    label: "5",
    description: "",
  },
  {
    label: "6",
    description: "",
  },
];
const SidebarLayout = () => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", }}>
        <ReusableDrawer />
        <CustomizedStepper steps={steps} />
      </Box>
    </>
  );
}

export default SidebarLayout