import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
const drawerWidth = "4vw";

const steps = [
  { label: "Select an app", path: "/selectapps" },
  { label: "App Name & Logo", path: ["/appcustomization", "/appscreenspage1", "/appscreenspage2", "/appscreenspage3"] },
  { label: "App features", path: "/appfeature" },
  { label: "Platform & Delivery details", path: "/platformdelivery" },
  { label: "Additional Information", path: "/additionalinfo" },
  { label: "Payment Preferences", path: "/payments" },
  { label: "Additional Services", path: "/services" },
  { label: "App Review", path: "/appreview" },
];

const CustomizedStepper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [moveDirection, setMoveDirection] = useState("forward");
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set()); // Track completed steps

  const currentStep = steps.findIndex((step) => {
    // console.log(step.path);
    // console.log(location.pathname);
    return step.path.includes(location.pathname);
});

  const handleStepClick = (index) => {
    console.log("stp",steps[index].path)
    navigate(steps[index].path);
  };

  useEffect(() => {
    setActiveStep(currentStep);

    // Mark all steps before the current step as completed
    const completed = new Set();
    for (let i = 0; i < currentStep; i++) {
      completed.add(i);
    }
    setCompletedSteps(completed);
  }, [currentStep]);
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        display: { xs: "none", sm: "none", md: "none", lg: "block" }, // Hide on xs, sm, md and show on lg and up
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          // width: drawerWidth,
          // boxSizing: "border-box",
          // position: "relative", // Ensure the drawers are positioned correctly
          // padding: "10px 6px",
          width: drawerWidth,
          boxSizing: "border-box",
          position: "relative",
          padding: "10px 6px",
          height: "100%", // Set the drawer height to 100% of the viewport
          overflow: "hidden", // Prevent overflow
        },
      }}
    >
      <Box
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          height: "auto",
          position: "fixed",
          backgroundColor: "#FFFFFF",
        }}
      >
        {hovered && (
          <Box
            sx={{
              position: "absolute",
              left: hovered ? "56px" : "39px", // Use 'hovered' to adjust the position
              height: "auto",
              width: hovered ? "240px" : "60px",
              borderRadius: "0px 8px 8px 0px",
              backgroundColor: "#FFFFFF",
              zIndex: 1,
              padding: "15px",
              transition: "width 1s ease, left 1s ease",
              boxShadow:
                "0px 25px 60px -15px rgba(16, 24, 40, 0.2); 0px 25px 60px -15px rgba(16, 24, 40, 0.12)",
            }}
          >
            {steps.map((item, index) => (
              <Box
                // onClick={() => { navigate(`${item.path}`); stepperHandler(index+1)}}
                // onClick={() => navigate(`${item.path}`)}
                onClick={() => {
              
                  console.log(`Navigating to: ${item.path}`);
                  console.log("Navigating to:", index);
                  if (index === 1)
                  {
                    navigate("/appcustomization")
                  }
                  else
                  {
                  navigate(`${item.path}`); 
                  }
                }}
                key={index}
                sx={{
                  cursor: "pointer",
                  mb: 1,
                  mt: "8px",
                  borderRadius: "10px",
                  padding: "10px 15px",
                  backgroundColor:
                    index === currentStep ? "#14042C" : "#FFFFFF", // Default background color or hovered color
                  color: index === currentStep ? "#FFFFFF" : "#14042C", // Inverted color if index is 1
                  "& .hover-effect": {
                    color: index === currentStep ? "#FFFFFF" : "#14042C", // Ensure text color inversion
                  },
                  // transition: "background-color 0.3s ease, color 0.3s ease", // Smooth transition for hover effects
                  "&:hover": {
                    backgroundColor: "#14042C", // Dark background color on hover
                    color: "#FFFFFF",
                    boxShadow:
                      "0px 25px 60px -15px rgba(16, 24, 40, 0.2); 0px 25px 60px -15px rgba(16, 24, 40, 0.12)",
                    "& .hover-effect": {
                      color: "#FFFFFF", // Invert text color on hover
                    },
                    // "& .circle-effect": {
                    //   background:
                    //     "radial-gradient(50% 375.88% at 50% 50%, #FFFFFF 0%, #C8B9DE 67.9%, #6F5498 100%)", // Inverted gradient on hover
                    // },
                  },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: '"Inter", sans-serif',
                    fontSize: "13px",
                    lineHeight: "1.5rem",
                    fontWeight: 500,
                  }}
                >
                  Step{""}
                  {index + 1}
                </Typography>
                <Typography
                  className="hover-effect"
                  sx={{
                    fontFamily: '"Exo 2", sans-serif',
                    fontSize: "15px",
                    fontWeight: 500,
                    lineHeight: "1.75rem",
                    whiteSpace: "nowrap",
                    color: "#14042C", // Default text color
                    transition: "color 0.3s ease", // Smooth transition for text color
                  }}
                >
                  {item.label}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <Stepper
          activeStep={currentStep}
          orientation="vertical"
          sx={{
            padding: "10px 6px",
            backgroundColor: "#C8B9DE",
            borderRadius: "30px",
            "& .MuiStep-root": {
              // backgroundColor: "#6F5498",
              borderRadius: "30px",
              "& .MuiStepIcon-root": {
                fontSize: "2.4rem",
                color: "#14042C", // Correctly target the step icon
                border: "4px solid #6F5498",
                // border: activeStep === 1 ? "4px solid red" : "none",
                borderRadius: "30px",
              },
              "& .MuiStepLabel-root": {
                cursor: "pointer",
              },
              "& .MuiStepLabel-label": {
                // color: "#673AB7",
                // backgroundColor:'#14042C',
                color: "#673AB7",
                display: "flex",
                justifyContent: "center",
              },
              "& .MuiStepLabel-iconContainer": {
                padding: 0,
                // padding: "2px 5px",
                // height: "40px",
              },
              "& .MuiStepConnector-root": {
                flex: "1 1 auto",
                marginLeft: "-3px",
                transition: "height 0.5s ease-in-out",
                "& .MuiStepConnector-line": {
                  height: "100px", // Fixed height for testing
                },
              },
              "&.Mui-active .MuiStepLabel-label": {
                fontWeight: "bold",
              },
            },
            "&:hover": {
              // backgroundColor: "#FFFFFF",
            },
          }}
        >
          {steps.map((step, index) => (
            // <Step key={step.label} onClick={() => handleStepClick(index)}>
            //   <StepLabel />
            // </Step>
            <Step
              key={step.label}
              onClick={() => handleStepClick(index)}
              completed={completedSteps.has(index)} // Mark as completed if in the set
              sx={{
                "& .MuiStepLabel-label": {
                  backgroundColor:
                    activeStep === index ? "#6F5498" : "transparent", // Active step background color
                  color: activeStep === index ? "#FFFFFF" : "#673AB7", // Adjust text color
                  borderRadius: "20px",
                  transition: "background-color 0.5s ease", // Smooth transition
                },
              }}
            >
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </Box>
    </Drawer>
  );
};

export default CustomizedStepper;
