import { combineReducers } from "redux";
import emailLoginReducer from "./loginAndSignup/emailLoginReducer";
import loadSlectAppsReducer from "./stepsFlow/loadSelectAppsReducer";
import createUserAppReducer from "./stepsFlow/createUserAppReducer";
import updateCustomAppReducer from "./stepsFlow/updateCustomAppReducer";
import emailSignupReducer from "./loginAndSignup/emailSignupReducer";

import loadCouponReducer from "./coupons/loadCouponReducer";
import loadMyappsReducer from "./myapps/loadMyappsReducer";
import loadUserprofilesReducer from "./profile/loadUserprofileReducer";
import changePasswordReducer from "./profile/changepasswordReducer";
import updateTaxdetailsReducer from "./profile/updateTaxDtailsreducer";

import updatePlatformDeliveryReducer from "./stepsFlow/updatePlatformDeliveryReducer";
import updateAdditionalInfoReducer from "./stepsFlow/updateAdditionalInfoReducer";
import updateAppReviewReducer from "./stepsFlow/updateAppReviewReducer";
import updatePaymentsReducer from "./stepsFlow/updatePaymentsReducer";
import updateAppFeatureReducer from "./stepsFlow/updateAppFeatureReducer";
import updateServicesReducer from "./stepsFlow/updateServicesReducer";
import fetchContentReducer from "./sidebar/fetchContentReducer";
import loadSubAppFeatureReducer from "./stepsFlow/loadSubAppFeatureReducer";

import loadclimedappReducer from "./myapps/loadcliemdedappReducer";
import updateResubmitappReducer from "./myapps/updateResubmitappReducer";
import PaymentsummaryReducer from "./myapps/PaymenysummaryReducer";


import loadInvoiceReducer from "./myapps/loadInvoiceReducer";
import paymentListReducer from "./myapps/getpaymentsReducer";
import getNotificationsReducer from "./notifications/notificatinReducer";

import termsAndConditionsReducer from "./sidebar/termsAndConditionsReducer";
import privacyPolicyReducer from "./sidebar/privacyPolicyReducer";

import loadUserAppDetailsReducer from "./stepsFlow/loadUserAppDetailsReducer";
import loadUserAppFeatureReducer from "./stepsFlow/loadUserAppFeaturesReducer";
import loadUserAppSubFeaturesReducer from "./stepsFlow/loadUserAppSubFeaturesReducer";
import deleteUserAppSubFeatureReducer from "./stepsFlow/deleteUserAppSubFeaturesReducer";
import couponValidationReducer from "./stepsFlow/couponValidationReducer";
import postPaymentReducer from "./myapps/postPaymentReducer";
import getWalletReducer from "./wallet/getWalletReducer";
import bonusCommissionsReducer from "./bonus/bonusCommissionsReducer";
import appCommissionsReducer from "./bonus/appCommissionsReducer";
import razorpayPostReducer from "./razorpay/razorpayPostReducer";
import homepageSection1Reducer from "./homepage/homepageSection1Reducer";

import landingpageSection2Reducer from "./homepage/landingpageSection2Reducer";
import landingpageSection3Reducer from "./homepage/landingpageSection3Reducer";
import landingpageSection4Reducer from "./homepage/landingpageSection4Reducer";
import landingpageSection5Reducer from "./homepage/landingpageSection5Reducer";


const rootReducer = combineReducers({
  // login and signup flow ...
  emaillogindata: emailLoginReducer,
  emailsignupdata: emailSignupReducer,

  loadcoupondata:loadCouponReducer,
  loadmyappData:loadMyappsReducer,
  loaduserProfile:loadUserprofilesReducer,
  changepassword:changePasswordReducer,
  updateTaxdetails:updateTaxdetailsReducer,
  content:fetchContentReducer,
  termsAndConditions:termsAndConditionsReducer,
  privacyPolicy:privacyPolicyReducer,

  loadcoupondata: loadCouponReducer,
  loadmyappData: loadMyappsReducer,
  loaduserProfile: loadUserprofilesReducer,
  changepassword: changePasswordReducer,
  updateTaxdetails: updateTaxdetailsReducer,
  content: fetchContentReducer,

  // steps flow ...
  // step ...1
  selectappsdata: loadSlectAppsReducer,
  createuserappdata: createUserAppReducer,
  // step ...2
  updatecustomappdata: updateCustomAppReducer,
  // step ...3
  loaduserappdata: loadUserAppDetailsReducer,
  loaduserappfeaturedata: loadUserAppFeatureReducer,
  loaduserappsubfeaturedata: loadUserAppSubFeaturesReducer,
  updateappfeaturedata: updateAppFeatureReducer,
  loadsubfeaturedata: loadSubAppFeatureReducer,
  deleteuserappsubfeaturedata:deleteUserAppSubFeatureReducer,
  // step ...4
  updateplatformdeliverydata: updatePlatformDeliveryReducer,
  // step ...5
  updateAdditionalInfodata: updateAdditionalInfoReducer,
  // step ...6
  updatepaymentdata: updatePaymentsReducer,
  // step ...7
  updateservicedata: updateServicesReducer,
  // step ...8
  updateappreviewdata: updateAppReviewReducer,
  //climed apps
  climedappdetails: loadclimedappReducer,

  //update resubmitapp
  resubmitappdata: updateResubmitappReducer,

  //payment
  Payments: PaymentsummaryReducer,
  payment:postPaymentReducer,

  //paymest list fro climed app

  PaymentList: paymentListReducer,
  //invoiceData
  invoiceData: loadInvoiceReducer,

  //notifications
  notifications: getNotificationsReducer,
  couponData : couponValidationReducer,
  updateappreviewdata: updateAppReviewReducer, 

  //wallet
  wallets: getWalletReducer,
  //bonus
  bonus:bonusCommissionsReducer,
  appcommission:appCommissionsReducer,
  razorpaypost:razorpayPostReducer,

  //Landing Page
  homepagesection1:homepageSection1Reducer,
  landingpageseection2:landingpageSection2Reducer,
  landingpageseection3:landingpageSection3Reducer,
  landingpageseection4:landingpageSection4Reducer,
  landingpageseection5:landingpageSection5Reducer,
  
});
export default rootReducer;
