import React from 'react'
// import { Box, Typography } from "@mui/material";
import AppRoutes from "./AppRoutes";


const App = () => {
  return (
    <>
     
       
     
      <AppRoutes />
   
  
    </>
  );
};

export default App