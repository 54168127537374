import React, { useState } from 'react';
import { Box, Button, Container, Divider, Typography, Link, Grid, TextField, lighten } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableFormTextField from '../../../components/TextField';


const SetNewpasswordPage = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;


    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found
  return (
    <Grid container
    direction="column"
    sx={{
        height: '364px',
        background: `linear-gradient(to bottom, white 70%, ${extractedColor} 110%)`,
        border: '4px solid grey',
        borderRadius: '8px',
        alignItems: 'center'
    }}
>
    <Box item sx={{ textAlign: 'center', mt: '20px' }}>

        <img
            src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
                ? storedAppData.logo.selectedImage
                : "../assets/images/defaultLogo.png"}

            style={{ width: '25px', height: '25px' }}
        />


        {/* App Name */}
        <Typography sx={{ fontSize: '12px', fontFamily: '"Inter", sans-serif', fontWeight: '500', mt: '0px' }}>
            {storedAppData.application_name}
        </Typography>
    </Box>

    <Grid item sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, width: '252px', height: '165px', textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
        <Box

            sx={{

                padding: '8px 54px',
                borderRadius: '16px',
                textAlign: 'center',
                width: '100%',

            }}
        >
            <Grid item  sx={{justifyContent:'center'}}>
                <Box sx={{  }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', fontFamily: '"Inter", sans-serif', }}>Set new Password</Typography>
                    <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>
                    Enter new password
                    & re-enter new password
                    </Typography>
                </Box>
                </Grid>

                {/* Email and Password Input */}
               
           <Box sx={{ height: '20px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '4px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center',}}>
            <Typography sx={{ color: 'grey', fontSize: '9px', fontWeight: '500' }} >New Password</Typography>
          </Box>
          <Box sx={{ height: '20px', border: '1px solid black', padding: '3px 6px', width: '100%', borderRadius: '4px', textAlign: 'start', borderColor: `${lighten(extractedColor, 0.5)}`, alignContent: 'center', marginTop: '8px' }}>
            <Typography sx={{ color: 'grey', fontSize: '9px', fontWeight: '500' }} >Re-enter new Password</Typography>
          </Box>
                 
              <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      
                        backgroundColor: `${extractedColor}`,
                        '&:hover': { backgroundColor: `${extractedColor}` },
                        textTransform: 'none',
                        color: '#4B5563',
                        fontWeight: '600',
                        fontSize: '8px',
                        fontFamily: '"Inter", sans-serif',
                        borderRadius: '4px',
                        mt:'8px'
                        
                    }}
                >
                  Update Password
                </Button>
           
          

          
        </Box>
    </Grid>

</Grid>
  )
}

export default SetNewpasswordPage
