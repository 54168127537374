import * as types from "../../actions/actionTypes";
const initialState = {
  updatePaymentsDetails: {
    users: [],
    token: null,
    loading: false,
  },
};
const updatePaymentsReducer = (
  state = initialState.updatePaymentsDetails,
  action
) => {
  switch (action.type) {
    case types.UPDATE_PAYMENTS_START:
      // console.log('respin reducer', state);
      return {
        ...state,
        loading: true,
      }; 
    case types.UPDATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.UPDATE_PAYMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default updatePaymentsReducer;
