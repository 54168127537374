import * as types from "../../actions/actionTypes";
const initialState = {
    loadmyappDetils: {
    data: [],
    token: null,
    loading: false,
  },
  
};
const loadMyappsReducer = (
  state = initialState.loadmyappDetils,
  action
) => {
  switch (action.type) {
    case types.LOAD_ALLMYAPPS_START:
      // console.log('respin reducer', state);
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_ALLMYAPPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.LOAD_ALLMYAPPS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default loadMyappsReducer;
