import * as types from "../../actions/actionTypes";

const initialState = {
  couponValidation: {
    loading: false,
    error: null,
    isValid: false, // You can use this to track if the coupon is valid or not
  },
};

const couponValidationReducer = (
  state = initialState.couponValidation,
  action
) => {
  switch (action.type) {
    case types.COUPON_VALIDATION_START:
      return {
        ...state,
        loading: true,
        error: null, // Clear previous errors
      };
    case types.COUPON_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isValid: true, // Set this to true if the coupon is valid
      };
    case types.COUPON_VALIDATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload, // Set the error message from the action payload
      };
    default:
      return state;
  }
};

export default couponValidationReducer;
