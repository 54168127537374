import * as types from "../../actions/actionTypes";

const initialState = {
  deleteUserAppSubFeatureDetails: {
    users: [],
    token: null,
    loading: false,
  },
};
const deleteUserAppSubFeatureReducer = (
  state = initialState.deleteUserAppSubFeatureDetails,
  action
) => {
  switch (action.type) {
    //deleting UserAppSubFeatures  details
    case types.DELETE_USERAPP_SUBFEATURES_START:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_USERAPP_SUBFEATURES_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter((item) => item.id !== action.payload),
      };
    case types.DELETE_USERAPP_SUBFEATURES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default deleteUserAppSubFeatureReducer;
