import * as types from "../../actions/actionTypes";
const initialState = {
   loadInvoiceDetils: {
    data: [],
    token: null,
    loading: false,
  },
  
};
const loadInvoiceReducer = (
  state = initialState.loadInvoiceDetils,
  action
) => {
  switch (action.type) {
    case types.LOAD_INVOICE_START:
      // console.log('respin reducer', state);
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.LOAD_INVOICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default loadInvoiceReducer;
