import { Box, Typography } from '@mui/material'
import React from 'react'
import { Icon } from "@iconify/react";

const TopNotch = () => {
  return (
    <Box
    sx={{
      position: 'absolute',
      top: '5px',
      left: '50%',
      transform: 'translateX(-50%)',
      display:'flex',
      justifyContent: 'space-around',
      width: '100%', 
      alignItems:'center',
      padding:'0px 8px'
    }}
  >
    <Typography sx={{fontSize:'12px',fontFamily: '"Inter", sans-serif',width:'80px'}}>9:41</Typography>
    <Box sx={{ width: '80px',height: '20px',backgroundColor: 'black',   borderRadius: '50px',}}>

    </Box>
    <Box  sx={{display:'flex',gap:'4px',width:'80px',justifyContent:'flex-end'}}>
    <Icon icon="game-icons:network-bars" width="12px" height="12px"  style={{color:'#000000'}} />
    <Icon icon="material-symbols:wifi" width="12px" height="12px"  style={{color: '#000000'}} />
    <Icon icon="gg:battery" width="15px" height="15px"  style={{color: '#000000'}} />
    </Box>
    
    </Box>
  )
}

export default TopNotch
