import * as types from "../../actions/actionTypes";
const initialState = {
  loadCouonDetails: {
    data: [],
    token: null,
    loading: false,
  },
  
};
const loadCouponReducer = (
  state = initialState.loadCouonDetails,
  action
) => {
  switch (action.type) {
    case types.LOAD_COUPON_START:
      // console.log('respin reducer', state);
      return {
        ...state,
        loading: true,
      };
    case types.LOAD_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.LOAD_COUPON_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default loadCouponReducer;
