import * as types from "../../actions/actionTypes";

const initialState = {
  getNotificationDetils: {
    data: [],
    token: null,
    loading: false,
  },
};

const getNotificationsReducer = (
  state = initialState.getNotificationDetils,
  action
) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_START:
      // console.log('Loading started', state);
      return {
        ...state,
        loading: true,
      };
    case types.GET_NOTIFICATION_SUCCESS:
      // console.log('Loading success', action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.GET_NOTIFICATION_ERROR:
      //console.error('Loading error', action.payload); 
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getNotificationsReducer;
