import * as types from "../../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const updateResubmitappReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESUBMITAPP_START:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_RESUBMITAPP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.UPDATE_RESUBMITAPP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateResubmitappReducer;
