import { Box, Button, Grid, TextField, Typography, Card, CardContent, CardMedia, IconButton, lighten, } from '@mui/material'
import React from 'react'
import Navbar from './Navbar'
import { Icon } from "@iconify/react";
import ReusableButton from '../../../components/Button';

const ShoppingCartScreen = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;

    const products2 = [
        {
            name: 'Apple MacBook Pro',
            price: '₹3,39,915',
            originalPrice: '₹3,99,000',
            discount: '15% off',
            image: "./assets/images/Laptop1.png",
            rating: 3,
            liked: true,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop2.png",
            rating: 4,
            liked: false,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop3.png",
            rating: 4,
            liked: false,
        },
        {
            name: 'Lenovo Legion 9',
            price: '₹4,49,990',
            originalPrice: '₹6,29,890',
            discount: '28% off',
            image: "./assets/images/Laptop4.png",
            rating: 4,
            liked: false,
        },
    ];
    return (
        <Grid container
            direction="column"
            sx={{
                height: 'auto',
                border: '4px solid grey',
                paddingBottom:'5px',

                borderRadius: '8px',
            }}
        >
            <Navbar />
            <Grid container item sx={{ justifyContent: 'center' }}>
                <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
                    <Grid container sx={{ gap: '5px', mt: '10px' }}>
                        <Grid item xs={8.5} sx={{ border: `1px solid ${lighten(extractedColor, 0.5)}`, borderRadius: '8px', height: '180px', padding:{xs:'10px',lg:'5px'} }}>
                            <Typography sx={{ fontSize: '13px', fontFamily: '"Inter", sans-serif', fontWeight: '500', lineHeight: '16x', color: '#000000', borderBottom: `1px solid ${extractedColor}`, paddingBottom: '5px' }}>Shopping Cart</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    borderBottom: `1px solid ${lighten(extractedColor, 0.5)}`,
                                    mt: '5px',
                                    paddingBottom: '5px',
                                    flexDirection: { xs: 'column', sm: 'row' }  // stack for smaller screens
                                }}
                            >
                                <Box
                                    component="img"
                                    src="./assets/images/Laptop1.png"
                                    alt="No Image"
                                    sx={{
                                        width: { xs: '80px', sm: '100px' },  // smaller width for small screens
                                        height: { xs: '50px', sm: '64px' },  // smaller height for small screens
                                        mt: '7px',
                                        alignSelf: { xs: 'center', sm: 'flex-start' }  // center align on small screens
                                    }}
                                />
                                <Box sx={{ mt: { xs: '10px', sm: '0' }, ml: { sm: '5px' } }}>
                                    <Typography
                                        sx={{
                                            fontWeight: '400',
                                            fontSize: { xs: '10px', sm: '8px'},  // adjust font size for small screens
                                            color: '#000',
                                            fontFamily: '"Inter", sans-serif',
                                            lineHeight: '12px',
                                            mb: '3px',
                                            textAlign: { xs: 'center', sm: 'left' }
                                        }}
                                    >
                                        Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A  (16 Inch, Space Black, 2.16 kg)
                                    </Typography>

                                    {/* Price Section */}
                                    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>
                                            ₹3,39,915
                                        </Typography>

                                        <Typography sx={{ textDecoration: 'line-through', ml: '3px', mr: '3px', color: '#9e9e9e', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px', fontWeight: '500' }}>
                                            ₹3,99,900
                                        </Typography>
                                        <Typography sx={{ color: extractedColor, fontSize: '10px', fontFamily: '"Inter", sans-serif', lineHeight: '12px', fontWeight: '500' }}>
                                            15% off
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', mb: '10px', mt: '3px', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <Box sx={{ display: 'flex', width: '60px', height: '24px', border: `1px solid ${lighten(extractedColor, 0.5)}`, mr: '8px', borderRadius: '4px', textAlign: 'center' }}>
                                            <Box sx={{ borderRight: `1px solid ${lighten(extractedColor, 0.5)}`, width: '18px' }}>
                                                <Icon icon="ic:baseline-minus" width="12px" height="12px" style={{ color: '#000000' }} />
                                            </Box>
                                            <Box sx={{ borderRight: `1px solid ${lighten(extractedColor, 0.5)}`, width: '28px', paddingTop: '4px' }}>
                                                <Typography sx={{ fontSize: '12px' }}>1</Typography>
                                            </Box>
                                            <Box sx={{ width: '16px' }}>
                                                <Icon icon="ic:baseline-plus" width="12px" height="12px" style={{ color: '#000000' }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '80px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.5)}`, textAlign: 'center', alignItems: 'center', height: '24px' }}>
                                            <Typography sx={{ fontWeight: '500', fontSize: '8px', fontFamily: '"Inter", sans-serif' }}>Move to wishlist</Typography>
                                        </Box>

                                        <Box sx={{ width: '85px', padding: '5px', borderRadius: '4px', border: `1px solid ${lighten(extractedColor, 0.5)}`, textAlign: 'center', alignItems: 'center', height: '24px', ml: '8px' }}>
                                            <Typography sx={{ fontWeight: '500', fontSize: '8px', fontFamily: '"Inter", sans-serif' }}>Remove from cart</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    mb: '15px',


                                }}
                            >
                                <Box sx={{ mt: '5px' }}>
                                    <Typography sx={{ fontWeight: '500', fontSize: '9px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Total (1item)</Typography>
                                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>₹3,40,914</Typography>
                                </Box>

                                <ReusableButton
                                    sx={{
                                        '&:hover': { backgroundColor: `${extractedColor}` },
                                        textTransform: 'none',
                                        backgroundColor: `${extractedColor}`,
                                        height: '30px',
                                        padding: '6px 20px',
                                        borderRadius: '4px',
                                        mt: '7px',
                                        fontWeight: '500',
                                        fontFamily: '"Inter", sans-serif',
                                        fontSize: '10px'
                                    }}
                                >Proceed to payment</ReusableButton>
                            </Box>

                        </Grid>
                        <Grid item xs={3.3} >
                            <Box
                                sx={{
                                    border: `1px solid ${lighten(extractedColor, 0.5)}`,
                                    borderRadius: '8px',
                                    padding: '8px 10px',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',

                                }}
                            >
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '10px', lineHeight: '12px', paddingBottom: '6px', fontFamily: '"Inter", sans-serif', borderBottom: `1px solid ${extractedColor}`, }}>
                                    Price summary
                                </Typography>

                                {/* Coupon Input and Button */}
                                <Box sx={{ display: 'flex', gap: '5px', mb: '8px', mt: '8px' }}>
                                    <TextField
                                        fullWidth
                                        placeholder="Apply gift card or coupon code"
                                        variant="outlined"
                                        size="small"

                                        InputProps={{
                                            sx: {
                                                fontSize: '6px',
                                                borderRadius: '4px',
                                                color: '#000000'
                                            },
                                        }}
                                    />

                                    <Button

                                        color="secondary"
                                        sx={{
                                            backgroundColor: extractedColor,
                                            padding: '0px',
                                            borderRadius: '4px',
                                            textTransform: 'none',
                                            color: '#000000',
                                            fontSize: '9px',
                                            ':hover': { backgroundColor: extractedColor, color: '#000000' }
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </Box>

                                {/* Price Details */}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '2px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>Sub total</Typography>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>₹3,39,915</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '2px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>Taxes</Typography>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>+₹9,999</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '2px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>Discounts</Typography>
                                    <Typography sx={{ fontWeight: '400', color: extractedColor, fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>-₹9,999</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '2px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>Shipping</Typography>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>+₹999</Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        borderTop: '1px solid #ddd',
                                        paddingTop: '10px',

                                    }}
                                >
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', }}>Total</Typography>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', }}>₹3,40,914</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px', width: '100%' }}>
                            <Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>Recently Viewed</Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#6B7280', fontFamily: '"Inter", sans-serif', color: `${extractedColor}` }}>View all</Typography>
                        </Box>
                        <Grid container spacing={1} sx={{ mt: '6px' }}>
                            {products2.map((product, index) => (
                                <Grid item xs={3} key={index}>
                                    <Card sx={{ border: ` 1px solid ${extractedColor}`, borderRadius: '15px', padding: '12px', position: 'relative' }}>
                                        <Box sx={{ position: 'absolute', top: '4px', right: '1px' }}>
                                            <IconButton>
                                                <Icon
                                                    icon={product.liked ? "mdi:heart" : "mdi:heart-outline"}
                                                    style={{ color: `${extractedColor}`, fontSize: '15px' }}
                                                />
                                            </IconButton>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            height="90px"
                                            image={product.image}
                                            alt={product.name}
                                            sx={{ objectFit: 'contain', mt: '5px', paddingRight: '10px' }}
                                        />

                                        <Typography sx={{ fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif', mt: '3px' }}>
                                            {product.name}
                                        </Typography>
                                        <Box sx={{ mt: '2px' }}>

                                            <Typography sx={{ color: '#000', fontWeight: '500', fontSize: '10px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>
                                                {product.price}
                                            </Typography>

                                        </Box>


                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default ShoppingCartScreen
