import * as types from "../../actions/actionTypes";

const initialState = {
  postPaymentDetails: {
    data: [],
    token: null,
    loading: false,
    error: null,
  },
};

const postPaymentReducer = (state = initialState.postPaymentDetails, action) => {
  switch (action.type) {
    case types.POST_PAYMENT_START:
      console.log("Reducer: POST_PAYMENT_START");
      return {
        ...state,
        loading: true,
        error: null, // Clear any existing errors when starting a new request
      };
      
    case types.POST_PAYMENT_SUCCESS:
      console.log("Reducer: POST_PAYMENT_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload, // Save the response data
        error: null, // Clear any previous errors
      };
      
    case types.POST_PAYMENT_ERROR:
      console.error("Reducer: POST_PAYMENT_ERROR", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload, // Store the error message
      };
      
    default:
      return state;
  }
};

export default postPaymentReducer;
