import React, { createContext, useState, useContext } from "react";

const AppIdContext = createContext();

export const AppIdProvider = ({ children }) => {
  const [appId, setAppId] = useState(null);

  return (
    <AppIdContext.Provider value={{ appId, setAppId }}>
      {children}
    </AppIdContext.Provider>
  );
};

export const useAppId = () => useContext(AppIdContext);
