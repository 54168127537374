import * as types from "../../actions/actionTypes";
const initialState = {
    data: [],
    loading: false,
    error: null,
  };
  
  const appCommissionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.BONUS_COMMISSIONS_START:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case types.BONUS_COMMISSIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case types.BONUS_COMMISSIONS_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default appCommissionsReducer;
  