import React from "react";
import { Button } from "@mui/material";
const ReusableButton = (props) => {
  return (
    <>
      <Button
        variant={props.variant}
        fullWidth={props.fullWidth}
        onClick={props.onClick}
        type={props.type}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        disabled={props.disabled}
        sx={{
          color: "#111",
          bgcolor: "#FFD814",
          borderColor: "#FCD200",
          borderRadius: "md",
          textDecoration: "none",
          textTransform: "none",
          whiteSpace: "nowrap",
          "&:hover": {
            bgcolor: "#FCD200",
          },
         
          ...props.sx,
        }}
      >
        {props.children}
      </Button>
    </>
  );
};
export default ReusableButton;
