




import React, { useEffect, useState } from "react";
import { Box, List, Drawer, Typography, Grid, Slide } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { fetchContentInitiate } from "../redux/actions/sidebar/fetchContentAction";
import { useDispatch, useSelector } from "react-redux";

import SidebarCustomerSupport from "../pages/Dashboard/SidebarCustomerSupport";
const drawerWidth = "5vw";
const CustomBox = styled(Box)(({ theme, active }) => ({
  width: "100%",
  height: "3rem",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  padding: theme.spacing(1),
  "& .MuiTypography-root": {
    color: active ? theme.palette.text.primary : theme.palette.text.secondary,
    fontWeight: active ? "bold" : "normal",
  },
}));
const ReusableDrawer = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width:1100px)");
  const isMediumScreen = useMediaQuery("(min-width:1024px)");

  const drawerWidth = isLargeScreen
    ? "6vw"
    : isMediumScreen
      ? "9vw"
      : "defaultWidth";

  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  const [isHovered, setIsHovered] = useState(false);
  const [hasToken, setHasToken] = useState(localStorage.getItem("token"));
  const user_type =localStorage.getItem("user_type");
  //   dispatch(fetchContentInitiate());
  const content = useSelector((state) => state.content.data);

  console.log("content----->", content);
  // const customerSupportData = content.find(
  //   (item) => item.title === "Customer Support"
  // );
  // console.log("content----->", customerSupportData);

  useEffect(() => {
    dispatch(fetchContentInitiate());
  }, [dispatch]);
    return (
      <>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          ["& .MuiDrawer-paper"]: {
            width: drawerWidth,
            height: "100vh",
            boxSizing: "border-box",
          },
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              cursor: "pointer",
            }}
            src="/assets/images/favicon.png"
            onClick={() => navigate("/")}
          />
          <Typography variant="subtitle2" color="initial" fontWeight="500">
            Studio
          </Typography>
        </Box>
        {hasToken ?(
        <Grid sx={{ overflow: "auto", padding: "0.6rem" }}>
          <List sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <CustomBox
               active={user_type === "user" ? isActive("/dashboard") : isActive("/agency/dashboard")}
               onClick={() => navigate(user_type === "user" ? "/dashboard" : "/agency/dashboard")}
              sx={{ mt: 3 }}
            >
            {user_type === "user" && isActive("/dashboard") ? (
                <DashboardIcon />
              ) : user_type !== "user" && isActive("/agency/dashboard") ? (
                <DashboardIcon />
              ) : (
                <GridViewOutlinedIcon />
              )}
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{ mt: "0.4rem" }}
              >
                Dashboard
              </Typography>
            </CustomBox>
            <CustomBox
             
              active={user_type === "user" ? isActive("/dashboard/myapps") : isActive("/agency/dashboard/myapps")}
              onClick={() => navigate(user_type === "user" ? "/dashboard/myapps" : "/agency/dashboard/myapps")}
            >
                {user_type === "user" && isActive("/dashboard/myapps") ? (
                <InsertDriveFileIcon />
              ) : user_type !== "user" && isActive("/agency/dashboard/myapps") ? (
                <InsertDriveFileIcon />
              ) : (
                <InsertDriveFileOutlinedIcon />
              )}
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{ mt: "0.5rem", display: "flex", alignItems: "center" }}
              >
                MyApps
              </Typography>
            </CustomBox>
            <CustomBox
              active={isActive("/dashboard/coupons")}
              onClick={() => navigate("/dashboard/coupons")}
            >
              {isActive("/dashboard/coupons") ? (
                <ConfirmationNumberIcon />
              ) : (
                <ConfirmationNumberOutlinedIcon />
              )}
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{ mt: "0.4rem" }}
              >
                Coupons
              </Typography>
            </CustomBox>
            <CustomBox
              sx={{ mt: 25 }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <SupportAgentIcon />
              <Typography
                variant="subtitle2"
                color="initial"
                sx={{ mt: "0.4rem" }}
              >
                Support
              </Typography>
            </CustomBox>
          </List>
        </Grid>
        ):
        ( <CustomBox
          sx={{ mt:'450px' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <SupportAgentIcon />
          <Typography
            variant="subtitle2"
            color="initial"
            sx={{ mt: "0.4rem" }}
          >
            Support
          </Typography>
        </CustomBox>)}
      </Drawer>
      <SidebarCustomerSupport
        isHovered={isHovered}
        content={content}
        setIsHovered={setIsHovered}
      />
            
    </>
    );
};

export default ReusableDrawer;







