  import React from "react";
  import { InputAdornment, TextField } from "@mui/material";
  import { AccountCircle } from "@mui/icons-material";
  const ReusableFormTextField = (props) => {
    return (
      <>
        <TextField
          InputProps={{
            style: {
              // Example styles
              width: "100%",
              backgroundColor: "#fff",
              color: "black",
              borderRadius: "8px",
              padding: "10px 8px",
              height: "2.75rem",
              marginTop: "1rem",
              // textAlign: "left", 
              // border:'1px solid #cfcfcf',
              ...props.customStyles,
            },
            // disableunderline: true, //Removes the underline
            //  underline:false, // Removes the underline
            classes: {
              root: {
                borderBottom: "none", // Remove the underline
              },
            },
            startAdornment: (
              <InputAdornment position="start">
                {props.startIcon}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment >
                {props.endIcon}
              </InputAdornment>
            ),
            readOnly: props.readOnly || false,
          }}
          
          fullWidth
          placeholder={props.placeholder}
          margin={props.margin}
          label={props.label}
          id={props.id}
          name={props.name}
          type={props.type}
          variant={props.variant}
          value={props.value}
          onBlur={props.onBlur}
          onChange={props.onChange}
          error={props.error}
          helperText={props.helperText}
          size={props.size}
          autoComplete="off"
          select={props.select}
          displayEmpty={props.displayEmpty}
        
        >
          {props.children}
        </TextField>
      </>
    );
  };
  export default ReusableFormTextField;
