import * as types from '../../actions/actionTypes';

// Define the initial state for privacy policy
const initialState = {
  privacyPolicy: {
    data: null, // This will hold the fetched privacy policy data
    loading: false,
    error: null,
  },
};

// Define the privacyPolicyReducer function
const privacyPolicyReducer = (state = initialState.privacyPolicy, action) => {
  switch (action.type) {
    case types.PRIVACY_POLICY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.PRIVACY_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // Store the fetched data
      };
    case types.PRIVACY_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload, // Store the error message
      };
    default:
      return state;
  }
};

export default privacyPolicyReducer;
