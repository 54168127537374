//Google Login details actions
export const GOOGLE_SIGNIN_START = "GOOGLE_SIGNIN_START";
export const GOOGLE_SIGNIN_SUCCESS = "GOOGLE_SIGNIN_SUCCESS";
export const GOOGLE_SIGNIN_ERROR = "GOOGLE_SIGNIN_ERROR";
 
//Login using email id details actions
export const EMAIL_LOGIN_START = "EMAIL_LOGIN_START";
export const EMAIL_LOGIN_SUCCESS = "EMAIL_LOGIN_SUCCESS";
export const EMAIL_LOGIN_ERROR = "EMAIL_LOGIN_ERROR";
 
//Signup details actions
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
 
//Login using mobile number details actions
export const MOBILE_LOGIN_START = "MOBILE_LOGIN_START";
export const MOBILE_LOGIN_SUCCESS = "MOBILE_LOGIN_SUCCESS";
export const MOBILE_LOGIN_ERROR = "MOBILE_LOGIN_ERROR"
 
//Signup using email id details actions
export const EMAIL_SIGNUP_START = "EMAIL_SIGNUP_START";
export const EMAIL_SIGNUP_SUCCESS = "EMAIL_SIGNUP_SUCCESS";
export const EMAIL_SIGNUP_ERROR = "EMAIL_SIGNUP_ERROR";
//Signup using mobile number details actions
export const MOBILE_SIGNUP_START = "MOBILE_SIGNUP_START";
export const MOBILE_SIGNUP_SUCCESS = "MOBILE_SIGNUP_SUCCESS";
export const MOBILE_SIGNUP_ERROR = "MOBILE_SIGNUP_ERROR";
//Otp verification using email id details actions
export const EMAIL_OTPVERIFY_START = "EMAIL_OTPVERIFY_START";
export const EMAIL_OTPVERIFY_SUCCESS = "EMAIL_OTPVERIFY_SUCCESS";
export const EMAIL_OTPVERIFY_ERROR = "EMAIL_OTPVERIFY_ERROR";
// verifiOTP_cation using mobile number details actions
export const MOBILE_OTPVERIFY_START = "MOBILE_OTPVERIFY_START";
export const MOBILE_OTPVERIFY_SUCCESS = "MOBILE_OTPVERIFY_SUCCESS";
export const MOBILE_OTPVERIFY_ERROR = "MOBILE_OTPVERIFY_ERROR";
// verifiOTP_cation using mobile number details actions
export const NEWPASSWORD_OTPVERIFY_START = "NEWPASSWORD_OTPVERIFY_START";
export const NEWPASSWORD_OTPVERIFY_SUCCESS = "NEWPASSWORD_OTPVERIFY_SUCCESS";
export const NEWPASSWORD_OTPVERIFY_ERROR = "NEWPASSWORD_OTPVERIFY_ERROR";
// resend OTP details actions
export const CREATE_RESEND_OTP_START = "CREATE_RESEND_OTP_START";
export const CREATE_RESEND_OTP_SUCCESS = "CREATE_RESEND_OTP_SUCCESS";
export const CREATE_RESEND_OTP_ERROR = "CREATE_RESEND_OTP_ERROR";
// create password recovery details
export const CREATE_PASSWORD_RECOVERY_START = "CREATE_PASSWORD_RECOVERY_START";
export const CREATE_PASSWORD_RECOVERY_SUCCESS =
  "CREATE_PASSWORD_RECOVERY_SUCCESS";
export const CREATE_PASSWORD_RECOVERY_ERROR = "CREATE_PASSWORD_RECOVERY_ERROR";
 
// create new password recovery details
export const CREATE_NEW_PASSWORD_START = "CREATE_NEW_PASSWORD_START";
export const CREATE_NEW_PASSWORD_SUCCESS = "CREATE_NEW_PASSWORD_SUCCESS";
export const CREATE_NEW_PASSWORD_ERROR = "CREATE_NEW_PASSWORD_ERROR";
 
// update new password recovery details
export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
 
//create a new user
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
 
export const LOAD_USEPROFILE_START = "LOAD_USEPROFILE_START";
export const LOAD_USEPROFILE_SUCCESS = "LOAD_USEPROFILE_SUCCESS";
export const LOAD_USEPROFILE_ERROR = "LOAD_USEPROFILE_ERROR";
 
export const UPDATE_USEPROFILE_START = "UPDATE_USEPROFILE_START";
export const UPDATE_USEPROFILE_SUCCESS = "UPDATE_USEPROFILE_SUCCESS";
export const UPDATE_USEPROFILE_ERROR = "UPDATE_USEPROFILE_ERROR";
 
// Forget password OTP actions
export const FORGET_PASSWORD_OTP_START = "FORGET_PASSWORD_OTP_START";
export const FORGET_PASSWORD_OTP_SUCCESS = "FORGET_PASSWORD_OTP_SUCCESS";
export const FORGET_PASSWORD_OTP_ERROR = "FORGET_PASSWORD_OTP_ERROR";
 
// Add Role Actions
export const ADD_ROLE_START = "ADD_ROLE_START";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

// Add agent more contact details
export const ADD_AGENT_DATA_START = "ADD_AGENT_DATA_START";
export const ADD_AGENT_DATA_SUCCESS = "ADD_AGENT_DATA_SUCCESS";
export const ADD_AGENT_DATA_ERROR = "ADD_AGENT_DATA_ERROR";
 
export const GOOGLE_LOGIN_START = 'GOOGLE_LOGIN_START';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE';
 
export const EMAIL_OTP_SEND_START = 'EMAIL_OTP_SEND_START';
export const EMAIL_OTP_SEND_SUCCESS = 'EMAIL_OTP_SEND_SUCCESS';
export const EMAIL_OTP_SEND_ERROR = 'EMAIL_OTP_SEND_FAILURE';
 
export const ADD_MORE_CONTACT_DETAILS_START = "ADD_MORE_CONTACT_DETAILS_START";
export const ADD_MORE_CONTACT_DETAILS_SUCCESS = "ADD_MORE_CONTACT_DETAILS_SUCCESS";
export const ADD_MORE_CONTACT_DETAILS_ERROR = "ADD_MORE_CONTACT_DETAILS_ERROR";
// actions for 8 steps of the app flow
 
// loading select apps ---- step(1)
export const LOAD_SELECTAPPS_START = "LOAD_SELECTAPPS_START";
export const LOAD_SELECTAPPS_SUCCESS = "LOAD_SELECTAPPS_SUCCESS";
export const LOAD_SELECTAPPS_ERROR = "LOAD_SELECTAPPS_ERROR";
 
// creating select apps ---- step(1)
export const CREATE_SELECTAPPS_START = "CREATE_SELECTAPPS_START";
export const CREATE_SELECTAPPS_SUCCESS = "CREATE_SELECTAPPS_SUCCESS";
export const CREATE_SELECTAPPS_ERROR = "CREATE_SELECTAPPS_ERROR";
 
// updating the appcustomization ---- step(2)
export const UPDATE_CUSTOMAPP_START = "UPDATE_CUSTOMAPP_START";
export const UPDATE_CUSTOMAPP_SUCCESS = "UPDATE_CUSTOMAPP_SUCCESS";
export const UPDATE_CUSTOMAPP_ERROR = "UPDATE_CUSTOMAPP_ERROR";
 
// updating the appfeature ---- step(3)
export const LOAD_USERAPPDETAILS_START = "LOAD_USERAPPDETAILS_START";
export const LOAD_USERAPPDETAILS_SUCCESS = "LOAD_USERAPPDETAILS_SUCCESS";
export const LOAD_USERAPPDETAILS_ERROR = "LOAD_USERAPPDETAILS_ERROR";


export const LOAD_USERAPP_FEATURES_START = "LOAD_USERAPPF_FEATURES_START";
export const LOAD_USERAPP_FEATURES_SUCCESS = "LOAD_USERAPPF_FEATURES_SUCCESS";
export const LOAD_USERAPP_FEATURES_ERROR = "LOAD_USERAPPF_FEATURES_ERROR";

export const LOAD_USERAPP_SUBFEATURES_START = "LOAD_USERAPPF_SUBFEATURES_START";
export const LOAD_USERAPP_SUBFEATURES_SUCCESS = "LOAD_USERAPPF_SUBFEATURES_SUCCESS";
export const LOAD_USERAPP_SUBFEATURES_ERROR = "LOAD_USERAPPF_SUBFEATURES_ERROR";

export const DELETE_USERAPP_SUBFEATURES_START = "DELETE_USERAPPF_SUBFEATURES_START";
export const DELETE_USERAPP_SUBFEATURES_SUCCESS = "DELETE_USERAPPF_SUBFEATURES_SUCCESS";
export const DELETE_USERAPP_SUBFEATURES_ERROR = "DELETE_USERAPPF_SUBFEATURES_ERROR";

export const UPDATE_APPFEATURE_START = "UPDATE_APPFEATURE_START";
export const UPDATE_APPFEATURE_SUCCESS = "UPDATE_APPFEATURE_SUCCESS";
export const UPDATE_APPFEATURE_ERROR = "UPDATE_APPFEATURE_ERROR";

// Loading sub appfeature 
export const LOAD_SUB_APPFEATURE_START = "LOAD_SUB_APPFEATURE_START";
export const LOAD_SUB_APPFEATURE_SUCCESS = "LOAD_SUB_APPFEATURE_SUCCESS";
export const LOAD_SUB_APPFEATURE_ERROR = "LOAD_SUB_APPFEATURE_ERROR";

// update sub appfeature with id and param selecte === "true or false"
export const UPDATE_SUB_APPFEATURE_START = "UPDATE_SUB_APPFEATURE_START";
export const UPDATE_SUB_APPFEATURE_SUCCESS = "UPDATE_SUB_APPFEATURE_SUCCESS";
export const UPDATE_SUB_APPFEATURE_ERROR = "UPDATE_SUB_APPFEATURE_ERROR";

// updating the Platformdelivery ---- step(4)
export const UPDATE_PLATFORM_START = "UPDATE_PLATFORM_START";
export const UPDATE_PLATFORM_SUCCESS = "UPDATE_PLATFORM_SUCCESS";
export const UPDATE_PLATFORM_ERROR = "UPDATE_PLATFORM_ERROR";
 
// updating the Additional information ---- step(5)
export const UPDATE_ADDITIONALINFO_START = "UPDATE_ADDITIONALINFO_START";
export const UPDATE_ADDITIONALINFO_SUCCESS = "UPDATE_ADDITIONALINFO_SUCCESS";
export const UPDATE_ADDITIONALINFO_ERROR = "UPDATE_ADDITIONALINFO_ERROR";
 
// updating the Additional information ---- step(6)
export const UPDATE_PAYMENTS_START = "UPDATE_PAYMENTS_START";
export const UPDATE_PAYMENTS_SUCCESS = "UPDATE_PAYMENTS_SUCCESS";
export const UPDATE_PAYMENTS_ERROR = "UPDATE_PAYMENTS_ERROR";
 
// updating the Additional Services ---- step(7)
export const UPDATE_SERVICES_START = "UPDATE_SERVICES_START";
export const UPDATE_SERVICES_SUCCESS = "UPDATE_SERVICES_SUCCESS";
export const UPDATE_SERVICES_ERROR = "UPDATE_SERVICES_ERROR";
 
// updating the App review ---- step(8)
export const UPDATE_APPREVIEW_START = "UPDATE_APPREVIEW_START";
export const UPDATE_APPREVIEW_SUCCESS = "UPDATE_APPREVIEW_SUCCESS";
export const UPDATE_APPREVIEW_ERROR = "UPDATE_APPREVIEW_ERROR";
 
// coupon actions
export const LOAD_COUPON_START = "LOAD_COUPON_START";
export const LOAD_COUPON_SUCCESS ="LOAD_COUPON_SUCCESS";
export const LOAD_COUPON_ERROR = "ULOAD_COUPON_ERROR";
//my apps data
export const LOAD_ALLMYAPPS_START = "LOAD_ALLMYAPPS_START";
export const LOAD_ALLMYAPPS_SUCCESS ="LOAD_ALLMYAPPS_SUCCESS";
export const LOAD_ALLMYAPPS_ERROR = "LOAD_ALLMYAPPS_ERROR";
//profile  data
export const LOAD_USERPROFILE_START = "LOAD_USERPROFILE_START";
export const LOAD_USERPROFILE_SUCCESS ="LOAD_USERPROFILE_SUCCESS";
export const LOAD_USERPROFILE_ERROR = "ULOAD_USERPROFILE_ERROR";
//Update password
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS ="CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
//Update Tax
export const UPDATE_TAXDETAILS_START = "UPDATE_TAXDETAILS_START";
export const UPDATE_TAXDETAILS_SUCCESS ="UPDATE_TAXDETAILS_SUCCESS";
export const UPDATE_TAXDETAILS_ERROR = "UPDATE_TAXDETAILS_ERROR";
//profile  data
export const UPDATE_USERPROFILE_START ="UPDATE_USERPROFILE_START";
export const UPDATE_USERPROFILE_SUCCESS ="UPDATE_USERPROFILE_SUCCESS";
export const UPDATE_USERPROFILE_ERROR = "UPDATE_USERPROFILE_ERROR";

//email otp resend
export const EMAIL_OTP_RESEND_START = 'EMAIL_OTP_SEND_START';
export const EMAIL_OTP_RESEND_SUCCESS = 'EMAIL_OTP_SEND_SUCCESS';
export const EMAIL_OTP_RESEND_ERROR = 'EMAIL_OTP_SEND_FAILURE';
//mobile otp in sign up
export const MOBILE_OTP_SEND_START = "MOBILE_OTP_SEND_START";
export const MOBILE_OTP_SEND_SUCCESS = "MOBILE_OTP_SEND_SUCCESS";
export const MOBILE_OTP_SEND_ERROR = "MOBILE_OTP_SEND_ERROR"
//mobile otp verify
  export const MOBILE_OTP_VERIFY_START = "MOBILE_OTP_VERIFY_START";
  export const MOBILE_OTP_VERIFY_SUCCESS = "MOBILE_OTP_VERIFY_SUCCESS";
  export const MOBILE_OTP_VERIFY_ERROR = "MOBILE_OTP_VERIFY_ERROR"

 //mobile otp resend
 export const MOBILE_OTP_RESEND_START = "MOBILE_OTP_RESEND_START";
 export const MOBILE_OTP_RESEND_SUCCESS = "MOBILE_OTP_RESEND_SUCCESS";
 export const MOBILE_OTP_RESEND_ERROR = "MOBILE_OTP_RESEND_ERROR"

 //customer support get call

export const FETCH_CONTENT_START = 'FETCH_CONTENT_REQUEST';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';
//my apps 
export const LOAD_CLAIMEDAPP_START ="LOAD_CLAIMEDAPP_START";
export const LOAD_CLAIMEDAPP_SUCCESS ="LOAD_CLAIMEDAPP_SUCCESS";
export const LOAD_CLAIMEDAPP_ERROR = "LOAD_CLAIMEDAPP_ERROR";

//update resubmit app 
export const UPDATE_RESUBMITAPP_START ="UPDATE_RESUBMITAPP_START";
export const UPDATE_RESUBMITAPP_SUCCESS ="UPDATE_RESUBMITAPP_SUCCESS";
export const UPDATE_RESUBMITAPP_ERROR = "UPDATE_RESUBMITAPP_ERROR";

//update claimeda  app  status
export const UPDATE_CLAIMEDAPPSTATUS_START ="UPDATE_CLAIMEDAPPSTATUS_START";
export const UPDATE_CLAIMEDAPPSTATUS_SUCCESS ="UPDATE_CLAIMEDAPPSTATUS_SUCCESS";
export const UPDATE_CLAIMEDAPPSTATUS_ERROR = "UPDATE_CLAIMEDAPPSTATUS_ERROR";



//load payment summary
export const LOAD_PAYMENTSUMMARY_START ="LOAD_PAYMENTSUMMARY_START";
export const LOAD_PAYMENTSUMMARY_SUCCESS ="LOAD_PAYMENTSUMMARY_SUCCESS";
export const LOAD_PAYMENTSUMMARY_ERROR = "LOAD_PAYMENTSUMMARY_ERROR";

//get payent details
export const GET_PAYMENTDETAILS_START ="GET_PAYMENTDETAILS_START";
export const GET_PAYMENTDETAILS_SUCCESS ="GET_PAYMENTDETAILS_SUCCESS";
export const GET_PAYMENTDETAILS_ERROR = "GET_PAYMENTDETAILS_ERROR";

//post payment
export const POST_PAYMENT_START ="POST_PAYMENTDETAILS_START";
export const POST_PAYMENT_SUCCESS ="POST_PAYMENTDETAILS_SUCCESS";
export const POST_PAYMENT_ERROR = "POST_PAYMENTDETAILS_ERROR";


//get payent details
export const LOAD_INVOICE_START ="LOAD_INVOICE_START";
export const LOAD_INVOICE_SUCCESS ="LOAD_INVOICE_SUCCESS";
export const LOAD_INVOICE_ERROR = "LOAD_INVOICE_ERROR";


//get notifications
export const GET_NOTIFICATION_START ="GET_NOTIFICATION_START";
export const GET_NOTIFICATION_SUCCESS ="GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

//delete user(soft delete)
export const DELETE_USER_START ="DELETE_USER_START";
export const DELETE_USER_SUCCESS ="DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";



//terms and conditions 
export const TERMS_AND_CONDITIONS_START ="TERMS_AND_CONDITIONS_START";
export const TERMS_AND_CONDITIONS_SUCCESS ="TERMS_AND_CONDITIONS_SUCCESS";
export const TERMS_AND_CONDITIONS_ERROR = "TERMS_AND_CONDITIONS_ERROR";

//Privacy policy 
export const PRIVACY_POLICY_START ="PRIVACY_POLICY_START";
export const PRIVACY_POLICY_SUCCESS ="PRIVACY_POLICY_SUCCESS";
export const PRIVACY_POLICY_ERROR = "PRIVACY_POLICY_ERROR";

//update selected features

export const UPDATE_SELECTED_FEATURES_START ="UPDATE_SELECTED_FEATURES_START";
export const UPDATE_SELECTED_FEATURES_SUCCESS ="UPDATE_SELECTED_FEATURES_SUCCESS";
export const UPDATE_SELECTED_FEATURES_ERROR = "UPDATE_SELECTED_FEATURES_ERROR";

//cupon validation
export const COUPON_VALIDATION_START="COUPON_VALIDATION_START";
export const COUPON_VALIDATION_SUCCESS ="COUPON_VALIDATION_SUCCESS";
export const COUPON_VALIDATION_ERROR = "COUPON_VALIDATION_ERROR";


// delete uploaded file in 5th step
export const DELETE_DOCUMENT_START ="DELETE_DOCUMENT_START";
export const DELETE_DOCUMENT_SUCCESS ="DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";

//Get Wallet Data
export const GET_WALLET_START ="GET_WALLET_START";
export const GET_WALLET_SUCCESS ="GET_WALLET_SUCCESS";
export const GET_WALLET_ERROR = "GET_WALLET_ERROR";

export const SET_WALLET_PASSCODE_START ="SET_WALLET_PASSCODE_START";
export const SET_WALLET_PASSCODE_SUCCESS ="SET_WALLET_PASSCODE_SUCCESS";
export const SET_WALLET_PASSCODE_ERROR = "SET_WALLET_PASSCODE_ERROR";

export const VERIFY_PASSCODE_START ="VERIFY_PASSCODE_START";
export const VERIFY_PASSCODE_SUCCESS ="VERIFY_PASSCODE_SUCCESS";
export const VERIFY_PASSCODE_ERROR = "VERIFY_PASSCODE_ERROR";

export const BONUS_COMMISSIONS_START ="BONUS_COMMISSIONS_START";
export const BONUS_COMMISSIONS_SUCCESS ="BONUS_COMMISSIONS_SUCCESS";
export const BONUS_COMMISSIONS_ERROR = "BONUS_COMMISSIONS_ERROR";

export const APP_COMMISSIONS_START ="APP_COMMISSIONS_START";
export const APP_COMMISSIONS_SUCCESS ="APP_COMMISSIONS_SUCCESS";
export const APP_COMMISSIONS_ERROR = "APP_COMMISSIONS_ERROR";

export const REDEEM_WALLET_START ="REDEEM_WALLET_START";
export const REDEEM_WALLET_SUCCESS ="REDEEM_WALLET_SUCCESS";
export const REDEEM_WALLET_ERROR = "REDEEM_WALLET_ERROR";

//RazorPay
export const RAZORPAY_START ="RAZORPAY_START";
export const RAZORPAY_SUCCESS ="RAZORPAY_SUCCESS";
export const RAZORPAY_ERROR = "RAZORPAY_ERROR";

//Home Screen

export const HOMEPAGE_SECTION1_START ="HOMEPAGE_SECTION1_START";
export const HOMEPAGE_SECTION1_SUCCESS ="HOMEPAGE_SECTION1_SUCCESS";
export const HOMEPAGE_SECTION1_ERROR = "HOMEPAGE_SECTION1_ERROR";

export const  LANDINGPAGE_SECTION2_START ="LANDINGPAGE_SECTION2_START";
export const LANDINGPAGE_SECTION2_SUCCESS ="LANDINGPAGE_SECTION2_SUCCESS";
export const LANDINGPAGE_SECTION2_ERROR = "LANDINGPAGE_SECTION2_ERROR";

export const  LANDINGPAGE_SECTION3_START ="LANDINGPAGE_SECTION3_START";
export const LANDINGPAGE_SECTION3_SUCCESS ="LANDINGPAGE_SECTION3_SUCCESS";
export const LANDINGPAGE_SECTION3_ERROR = "LANDINGPAGE_SECTION3_ERROR";

export const  LANDINGPAGE_SECTION4_START ="LANDINGPAGE_SECTION4_START";
export const LANDINGPAGE_SECTION4_SUCCESS ="LANDINGPAGE_SECTION4_SUCCESS";
export const LANDINGPAGE_SECTION4_ERROR = "LANDINGPAGE_SECTION4_ERROR";

export const  LANDINGPAGE_SECTION5_START ="LANDINGPAGE_SECTION5_START";
export const LANDINGPAGE_SECTION5_SUCCESS ="LANDINGPAGE_SECTION5_SUCCESS";
export const LANDINGPAGE_SECTION5_ERROR = "LANDINGPAGE_SECTION5_ERROR";