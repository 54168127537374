import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { AppIdProvider } from "./components/AppId";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
const root = ReactDOM.createRoot(document.getElementById("root"));

// let theme = createTheme({
//   palette: {
//     background: {
//       // default:'steelblue',
//       // default: "#f3f6f999",
//       // default: "#f1f3f6",
//       // default: "#e7f5ff",
//       // default: "#fff",
//       default:'#cae4ff; background-image: radial-gradient(at 99.3% 6.6%, hsl(235, 50%, 80%) 0px, transparent 50%),radial-gradient(at -0.2% 94.9%, hsl(230, 48%, 76%) 0px, transparent 50%),radial-gradient(at 83.0% 61.5%, hsl(255, 96%, 91%) 0px, transparent 50%),radial-gradient(at 58.2% 82.1%, hsl(255, 96%, 91%) 0px, transparent 50%),radial-gradient(at 92.7% 91.3%, hsl(18, 89%, 86%) 0px, transparent 50%),radial-gradient(at -0.8% 5.7%, hsl(18, 89%, 86%) 0px, transparent 50%)'
//     },
//     // primary: {
//     //   main: "#FF5733", // Your primary color
//     // },
//     secondary: {
//       main: "#FF4081", // Your secondary color
//     },
//   },
//   typography: {
//     fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//     h1: {
//       fontSize: "2.5rem",
//       fontWeight: 700,
//     },
//     h2: {
//       fontSize: "2rem",
//       lineHeight: 1,
//       fontWeight: 500,
//     },
//     h3: {
//       fontSize: "1.8rem",
//       fontWeight: 500,
//     },
//     h4: {
//       fontSize: "1.5rem",
//       fontWeight: 400,
//     },
//     h5: {
//       fontSize: "1.2rem",
//       fontWeight: 300,
//     },
//     h6: {
//       fontSize: "1rem",
//       fontWeight: 200,
//     },
//   },
//   // components: {
//   //   MuiAppBar: {
//   //     styleOverrides: {
//   //       root: {
//   //         backgroundColor: "#eee", // Navbar background color
//   //         color: "#333", // Navbar text color
//   //       },
//   //     },
//   //   },
//   // },
//   components: {
//     // CTRL + SPACE to find the component you would like to override.
//     // For most of them you will need to adjust just the root...

//     MuiAppBar: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "#eee", // Navbar background color
//           color: "#333", // Navbar text color
//         },
//       },
//     },
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           // "& label": {
//           //   color: "#3E68A8",
//           // },
//           // "& label.Mui-focused": {
//           //   color: "#3E68A8",
//           // },
//           "& .MuiInput-underline:after": {
//             borderBottomColor: "#3E68A8",
//           },
//           "& .MuiOutlinedInput-root": {
//             "& fieldset": {
//               borderColor: "#3E68A8",
//             },
//             "&:hover fieldset": {
//               borderColor: "#3E68A8",
//               borderWidth: "0.15rem",
//             },
//             "&.Mui-focused fieldset": {
//               borderColor: "#3E68A8",
//             },
//           },
//         },
//       },
//     },
//   },
// });
// let theme = createTheme();
let theme = createTheme({
  palette: {
    background: {
      default: "#cae4ff",
      custom: `radial-gradient(at 99.3% 6.6%, hsl(235, 50%, 80%) 0px, transparent 50%),
                radial-gradient(at -0.2% 94.9%, hsl(230, 48%, 76%) 0px, transparent 50%),
                radial-gradient(at 83.0% 61.5%, hsl(255, 96%, 91%) 0px, transparent 50%),
                radial-gradient(at 58.2% 82.1%, hsl(255, 96%, 91%) 0px, transparent 50%),
                radial-gradient(at 92.7% 91.3%, hsl(18, 89%, 86%) 0px, transparent 50%),
                radial-gradient(at -0.8% 5.7%, hsl(18, 89%, 86%) 0px, transparent 50%)`,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#cae4ff",
          backgroundImage: `radial-gradient(at 99.3% 6.6%, hsl(235, 50%, 80%) 0px, transparent 50%),
                            radial-gradient(at -0.2% 94.9%, hsl(230, 48%, 76%) 0px, transparent 50%),
                            radial-gradient(at 83.0% 61.5%, hsl(255, 96%, 91%) 0px, transparent 50%),
                            radial-gradient(at 58.2% 82.1%, hsl(255, 96%, 91%) 0px, transparent 50%),
                            radial-gradient(at 92.7% 91.3%, hsl(18, 89%, 86%) 0px, transparent 50%),
                            radial-gradient(at -0.8% 5.7%, hsl(18, 89%, 86%) 0px, transparent 50%)`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Provider store={store}>
        <AppIdProvider>
          <App />
        </AppIdProvider>
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
);
