import * as types from '../../actions/actionTypes';

// Define the initial state for terms and conditions
const initialState = {
  termsAndConditions: {
    data: null, // This will hold the fetched terms and conditions data
    loading: false,
    error: null,
  },
};

// Define the termsAndConditionsReducer function
const termsAndConditionsReducer = (state = initialState.termsAndConditions, action) => {
  switch (action.type) {
    case types.TERMS_AND_CONDITIONS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload, // Store the fetched data
      };
    case types.TERMS_AND_CONDITIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload, // Store the error message
      };
    default:
      return state;
  }
};

export default termsAndConditionsReducer;
