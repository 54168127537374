import * as types from "../../actions/actionTypes";

// Define the initial state for homepage section 1
const initialState = {
  section1: {
    data: [], // Holds the fetched homepage section 1 data
    loading: false,
    error: null,
  },
};

// Define the reducer function
const homepageSection1Reducer = (state = initialState.section1, action) => {
  switch (action.type) {
    case types.HOMEPAGE_SECTION1_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.HOMEPAGE_SECTION1_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.HOMEPAGE_SECTION1_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default homepageSection1Reducer;
