import React from 'react'
import Navbar from './Navbar';
import { Box, Button, Grid, IconButton, lighten, Typography } from '@mui/material';
import { Icon } from "@iconify/react";
import ReusableButton from '../../../components/Button';

const InnerProductScreen = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;
    return (
        <Grid container
            direction="column"
            sx={{
                height: '400px',
                border: '4px solid grey',
                borderRadius: '8px',
            }}
        >
            <Navbar />
            <Grid container sx={{ justifyContent: 'center', mt: '5px' }}>
                <Grid sx={{ width: '92%', border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px',padding:'10px' }}>

                    <Grid sx={{ display: 'flex' }}>
                        <Grid item xs={4.5} sx={{ padding: '10px' }}>
                            <Box sx={{ padding: '50px 20px', border: `1px solid ${lighten(extractedColor,0.5)}`, justifyContent: 'center', borderRadius: '8px' }}>
                                <Box
                                    component="img"
                                    src='./assets/images/Laptop1.png'
                                    alt="Image 1"
                                    sx={{ height: '100px', width: '140px', borderRadius: '8px' }}
                                />
                            </Box>

                        </Grid>
                        <Grid item xs={7.5} sx={{ position: 'relative' }}>
                            <Box sx={{ position: 'absolute', top: '6.5px', right: '0px' }}>
                                <IconButton>
                                    <Icon
                                        icon="mdi:heart"
                                        style={{ color: `${extractedColor}`, fontSize: '15px' }} // Adjust color as needed
                                    />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography sx={{maxWidth:'330px', fontSize: '10.5px', lineHeight: '16px', fontWeight: '400', fontFamily: '"Inter", sans-serif', mt: '13px', color: '#000000' }}>Apple MacBook Pro Apple M3 Max - (48 GB/1 TB SSD/macOS Sonoma) MUW63HN/A(16 Inch, Space Black, 2.16 kg)</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '600', fontFamily: '"Inter", sans-serif', color: '#4B5563', mt: '2px',mb:'1px' }}>Apple </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center',mt:'4px' }}>
                                <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '15px', marginRight: '2px' }} />
                                <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '15px', marginRight: '2px' }} />
                                <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '15px', marginRight: '2px' }} />
                                <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '15px', marginRight: '2px' }} />
                                <Icon icon="mdi:star" style={{ color: extractedColor, fontSize: '15px', marginRight: '2px' }} />

                            </Box>
                            <Typography sx={{ fontSize: '9px', fontWeight: '400', fontFamily: '"Inter", sans-serif',mt:'3px' }}>7327 ratings & 5467 reviews</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center',mt:'4px' }}>
                                <Typography variant="body1" sx={{ fontWeight: '500', color: '#000', fontSize: '13px' }}>
                                    ₹500000
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: '1px' }}>
                                    <Typography sx={{ textDecoration: 'line-through', ml: '4px', color: '#9e9e9e', fontSize: '13px' }}>
                                        ₹550000
                                    </Typography>
                                    <Typography sx={{ color: extractedColor, fontSize: '13px', ml: '4px' }}>
                                        15% off
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ textAlign: 'start',mt:'4px' }}>
                                <ul style={{ paddingLeft: '0px', marginLeft: '0px', listStylePosition: 'inside', marginTop: '0px', fontSize: '10px', fontWeight: '400', fontFamily: '"Inter", sans-serif', }}>
                                    <li>Apple M3 Max Processor</li>
                                    <li>16GB RAM</li>
                                    <li>1TB SSD Storage</li>
                                    <li>Built-in Apps: App Store, Books, Calendar. </li>
                                    <li>macOS Sonoma</li>
                                    <li>Built-in Apps: App Store, Books, Calendar, Contacts, FaceTime, </li>
                                </ul>
                            </Box>

                        </Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', ml: '10px', gap: '10px' }}>
                        <Box sx={{ width: '40px', height: '40px', border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '5px', borderRadius: '4px' }}>
                            <img src="./assets/images/InnerProduct1.png" alt="image1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                        </Box>
                        <Box sx={{ width: '40px', height: '40px', border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '5px', borderRadius: '4px' }}>
                            <img src="./assets/images/InnerProduct2.png" alt="image1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                        </Box>
                        <Box sx={{ width: '40px', height: '40px', border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '5px', borderRadius: '4px' }}>
                            <img src="./assets/images/InnerProduct3.png" alt="image1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                        </Box>
                        <Box sx={{ width: '40px', height: '40px', border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '5px', borderRadius: '4px' }}>
                            <img src="./assets/images/InnerProduct4.png" alt="image1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: lighten(extractedColor, 0.5),
                                color: '#000',
                                borderRadius: '6px',
                                width: '115px',
                                height: '30px',
                                fontSize: '12px',
                                fontWeight: '500',
                                fontFamily: '"Inter", sans-serif',
                                textTransform: 'none',
                                mt: '5px',
                                '&:hover': {
                                    backgroundColor: lighten(extractedColor, 0.5),
                                    color: '#000000'
                                },
                            }}
                        >
                            Buy Now
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                border: `1px solid ${lighten(extractedColor,0.5)}`,
                                color: '#000',
                                borderRadius: '6px',
                                width: '115px',
                                height: '30px',
                                fontSize: '12px',
                                fontWeight: '500',
                                fontFamily: '"Inter", sans-serif',
                                textTransform: 'none',
                                mt: '5px',
                                '&:hover': {

                                    color: '#000000',
                                    border: `1px solid ${lighten(extractedColor,0.5)}`
                                },
                            }}
                        >
                            Add to cart
                        </Button>
                        <Box sx={{display:'flex',alignItems:'center',gap:'5px'}}>
                        <Icon icon="material-symbols:share" width="20px" height="20px"  style={{color:'#000000'}} />
                        <Typography sx={{ fontSize: '10px', fontWeight: '500', fontFamily: '"Inter", sans-serif', color: '#4B5563', mt: '3px' }}>Share</Typography>
                        </Box>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )
}

export default InnerProductScreen
