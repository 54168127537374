import API from "../../../API/API"; 
const api = new API();
const endPoints = "/contents?title=Customer Support";
export const fetchContentApi = async () => {
  return new Promise(async (resolve, reject) => {
    try {
     
      const result = await api.get(endPoints);
      console.log("Api data",result)
      resolve(result);
    } catch (error) {
     
      reject(error);
    }
  });
};
