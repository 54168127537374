import * as types from "../../actions/actionTypes";

const initialState = {
 changePasswordDetils: {
    data: [],
    token: null,
    loading: false,
  },
};

const changePasswordReducer = (
  state = initialState.changePasswordDetils,
  action
) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_START:
      // console.log('Loading started', state);
      return {
        ...state,
        loading: true,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      // console.log('Loading success', action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.CHANGE_PASSWORD_ERROR:
      console.error('Loading error', action.payload); 
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
