import * as types from "../../actions/actionTypes";
const initialState = {
   getPaymentDetils: {
    data: [],
    token: null,
    loading: false,
  },
 
};
const paymentListReducer = (state = initialState.getPaymentDetils, action) => {
  switch (action.type) {
    case types.GET_PAYMENTDETAILS_START:
      console.log("Reducer: GET_PAYMENTDETAILS_START");
      return {
        ...state,
        loading: true,
      };
    case types.GET_PAYMENTDETAILS_SUCCESS:
      console.log("Reducer: GET_PAYMENTDETAILS_SUCCESS", action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.GET_PAYMENTDETAILS_ERROR:
      console.error("Reducer: GET_PAYMENTDETAILS_ERROR", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default paymentListReducer;
 