// contentActions.js
import * as types from '../actionTypes';
import { fetchContentApi } from '../../apis/sidebar/fetchContentApi'; // Adjust the path as necessary

// Action creators
export const fetchContentStart = () => ({
  type: types.FETCH_CONTENT_START,
});

export const fetchContentSuccess = (data) => ({
  type: types.FETCH_CONTENT_SUCCESS,
  payload: data,
});

export const fetchContentFailure = (error) => ({
  type: types.FETCH_CONTENT_FAILURE,
  payload: error,
});

// Thunk action to handle async operation
export const fetchContentInitiate = () => {
  return function (dispatch) {
    dispatch(fetchContentStart());
    fetchContentApi()
      .then((response) => {
        dispatch(fetchContentSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchContentFailure(error.message));
      });
  };
};

export default {
    fetchContentInitiate,
};
