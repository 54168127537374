import { Box, Grid, Typography, TextField, IconButton, lighten } from '@mui/material';
import { Icon } from '@iconify/react';

const Footer = () => {
    const storedData = JSON.parse(sessionStorage.getItem("appData"));
    const storedAppData = storedData;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
  
  
    // Use a regular expression to extract the color code
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null; // Get the first match or null if not found
  return (
    <>
    <Grid container item sx={{ justifyContent: 'center', backgroundColor: lighten(extractedColor, 0.5), mt: '5px', paddingBottom: '10px' }}>
        <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
    <Box sx={{ backgroundColor: 'lighten(#f5e1fc, 0.3)',padding:'10px 0px', color: '#000',}}>
      <Grid container spacing={1}>
       <Grid item  xs={4}>
          <Typography sx={{ fontWeight: '600', fontFamily: '"Inter", sans-serif',fontSize:'13px',lineHeight:'16px' }}>
            Join the Family
          </Typography>
          <Typography sx={{ mt: 1, mb: 2, fontWeight: '400', fontFamily: '"Inter", sans-serif',fontSize:'8px',lineHeight:'10px',color:'#374151' }}>
            Instantly receive updates, access to exclusive deals, product launch details, and more.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, position: 'relative' }}>
            <TextField
              variant="standard"
              placeholder="email@example.com"
              fullWidth
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderBottom: '1px solid #000',
                  fontSize: '8px',
                  paddingBottom: '4px',
                }
              }}
            />
            <IconButton
              sx={{
                backgroundColor: extractedColor,
                position: 'absolute',
                right: '0px',
                height: '23px',
                width: '23px',
                marginLeft: '10px',
                mb:'2px',
                '&:hover': {
                  backgroundColor:extractedColor ,
                }
              }}
            >
              <Icon icon="mdi:arrow-top-right" style={{ color: 'white', fontSize: '10px' }} />
            </IconButton>
          </Box>
        </Grid>

      
        <Grid item xs={2.06} >
          <Typography  sx={{ fontWeight: '600', fontFamily: '"Inter", sans-serif',fontSize:'9px',lineHeight:'12px' }}>
            Customer service
          </Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Orders</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400'  }}>Deliveries</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400'  }}>Returns & refunds</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400'  }}>Payments</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400'  }}>FAQ</Typography>
        </Grid>

        <Grid item xs={2.06} >
          <Typography  sx={{ fontWeight: '600', fontFamily: '"Inter", sans-serif',fontSize:'9px',lineHeight:'12px' }}>
            Product guide
          </Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Electronics guide</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Home appliances guide</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Sports equipment guide</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Books & media guide</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Others</Typography>
        </Grid>

        <Grid item xs={2.06} >
          <Typography  sx={{ fontWeight: '600', fontFamily: '"Inter", sans-serif',fontSize:'9px',lineHeight:'12px' }}>
            Legal area
          </Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Privacy policy</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Terms & Conditions</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Information on reviews</Typography>
        </Grid>

        <Grid item xs={1.8} >
          <Typography  sx={{ fontWeight: '600', fontFamily: '"Inter", sans-serif',fontSize:'9px',lineHeight:'12px' }}>
            About us
          </Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Company</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Careers</Typography>
          <Typography sx={{ mt: 1, fontSize: '7px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '400',  }}>Services</Typography>
        </Grid>
      </Grid>
      
    </Box>
    </Grid>
    
    </Grid>
    <Box  sx={{ display:'flex',backgroundColor: lighten(extractedColor, 0.5),borderTop:`2px solid ${(extractedColor)}`,justifyContent:'center',alignItems:'center',padding:'8px',gap:'70px'}}>
    <Box sx={{display:'flex',alignItems:'center'}}>
    <img
          src={storedAppData && storedAppData.logo && storedAppData.logo.selectedImage
            ? storedAppData.logo.selectedImage
            : "../assets/images/defaultLogo.png"}

          style={{ width: '20px', height: '20px' }}
        />
        <Typography sx={{ml:'2px',fontSize: '12px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '500', }}>{storedAppData.application_name}</Typography>
    </Box>
        <Typography sx={{fontSize: '8px', fontFamily: '"Inter", sans-serif', color: '#374151',lineHeight:'8px', fontWeight: '500', }}>© 2024 - Company Name</Typography>
       <Box sx={{display:'flex',alignItems:'center',gap:'3px'}}>
       <Icon icon="ic:baseline-facebook" width="12px" height="12px"  style={{color: '#000000'}} />
       <Icon icon="mdi:instagram" width="12px" height="12px"  style={{color: '#000000'}} />
       <Icon icon="proicons:x-twitter" width="12px" height="12px"  style={{color: '#000000'}} />
       <Icon icon="mdi:youtube" width="12px" height="12px"  style={{color: '#000000'}} />

       </Box>

    </Box>
    </>
  );
};

export default Footer;
