import { Grid, Typography,Box,Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Icon } from "@iconify/react";

const SidebarCustomerSupport = ({ isHovered, content, setIsHovered }) => {
  console.log("content in customer",content[0]);
  const customerSupportData =content[0];
  const [token, setToken] = useState(null);

  useEffect(() => {
    
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);
  
  return (
    <>
   <Slide direction="right" in={isHovered} mountOnEnter unmountOnExit>
        <Box
           onMouseEnter={() => setIsHovered(true)}
           onMouseLeave={() => setIsHovered(false)}
          sx={{
            position: 'fixed',
            left: token ? "100px" : "160px",
            bottom: '5%',
            width: '392px',
            height: '258px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(40px)',
            // padding: '2px',
            display: 'flex',
            borderRadius: '16px',
            padding: '16px',
            zIndex: 9999,

            border: '1px solid rgba(200, 185, 222, 1)',
            // border: '1px solid black',

          }}
        >
          <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', }}>
            {/* <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', padding: '8px', border: '1px solid rgba(200, 185, 222, 1)', }}>
              <Typography sx={{color:'rgba(20, 4, 44, 1)',fontWeight:'500',fontFamily: '"Exo 2", sans-serif',fontSize:'20px',lineHeight:'28px'}}>Customer Support</Typography>
              <Typography sx={{color:'rgba(20, 4, 44, 1)',fontWeight:'400',fontFamily: '"Inter", sans-serif',fontSize:'14px',lineHeight:'20px'}}>If you have any questions or need assistance, please don't hesitate to reach out to us:</Typography>
            </Grid>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', padding: '5px', border: '1px solid rgba(200, 185, 222, 1)',textAlign:'center' }}>
              <Typography>Additional information here.</Typography>
            </Grid>
            <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', padding: '5px', border: '1px solid rgba(200, 185, 222, 1)',textAlign:'center' }}>
              <Typography>Additional information here.</Typography>
            </Grid> */}
            {customerSupportData ? (
              <>
                <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', border: '1px solid rgba(200, 185, 222, 1)', padding: ' 10px', }}>
                  <Typography sx={{ color: 'rgba(20, 4, 44, 1)', fontWeight: '500', fontFamily: '"Exo 2", sans-serif', fontSize: '20px', lineHeight: '28px' }}>{customerSupportData.title}</Typography>
                  <Typography sx={{ color: 'rgba(20, 4, 44, 1)', fontWeight: '400', fontFamily: '"Inter", sans-serif', fontSize: '14px', lineHeight: '20px' }}>{customerSupportData.data}</Typography>
                </Grid>
                <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px 10px', border: '1px solid rgba(200, 185, 222, 1)', textAlign: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Icon icon="ic:baseline-phone" width="17.96" height="17.96" style={{ color: '#14042C', marginRight: '8px' }} />
                    <Typography sx={{ color: '#14042C', fontFamily: '"Inter", sans-serif', fontSize: '16px', fontWeight: '500', lineHeight: '18px' }}>
                      {customerSupportData.subdata}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ backgroundColor: 'white', borderRadius: '8px', padding: '16px 10px', border: '1px solid rgba(200, 185, 222, 1)', textAlign: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Icon icon="ic:baseline-email" width="17.96" height="17.96" style={{ color: '#14042C' ,marginRight: '8px' }} />
                    <Typography sx={{ color: '#14042C', fontFamily: '"Inter", sans-serif', fontSize: '16px', fontWeight: '500', lineHeight: '18px' }} >{customerSupportData.subdata2}</Typography>

                  </Box>

                </Grid>
              </>) :
              (<Typography>no data</Typography>)}

          </Grid>
        </Box>
      </Slide>
    </>
   
  )
}

export default SidebarCustomerSupport
