import { Box, Button, Grid, lighten, Typography, IconButton, Avatar } from '@mui/material';
import React from 'react'
import Navbar from './Navbar';
import { Icon } from "@iconify/react";
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ProfileScreen = (data) => {
    const storedAppData = data.data;
    console.log("ddd", storedAppData);
    const colorGradient = storedAppData.theme_color;
    const colorMatch = colorGradient.match(/#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})/);
    const extractedColor = colorMatch ? colorMatch[0] : null;
    return (
        <Grid container
            direction="column"
            sx={{
                height: '500px',
                border: '4px solid grey',
                borderRadius: '8px',
            }}
        >
            <Navbar />
            <Grid container item sx={{ justifyContent: 'center' }}>
                <Grid item sx={{ width: '90%', borderRadius: '12px', }}>
                    <Grid container sx={{ mt: '5px' }}>
                        <Grid item xs={2.3} sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '8px', height: '300px', mr: '8px', padding: '10px' }}>
                            <Box sx={{ border: `1px solid ${extractedColor}`, borderRadius: '4px', padding: '5px', backgroundColor: lighten(extractedColor, 0.5) }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>My Profile</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Orders</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Saved Payments</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Wallet</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Reviews</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>FAQ</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Help & Support</Typography>
                            </Box>
                            <Box sx={{ padding: '5px', mt: '15px' }}>
                                <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '12px' }}>Settings</Typography>
                            </Box>

                        </Grid>
                        <Grid item xs={9.5} sx={{  padding: '5px', position: 'relative', }}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: lighten(extractedColor, 0.5),
                                        '&:hover': { backgroundColor: `${lighten(extractedColor,0.5)}` },
                                        textTransform: 'none',
                                        color: '#4B5563',
                                        fontWeight: '600',
                                        fontSize: '8px',
                                        fontFamily: '"Inter", sans-serif',
                                        borderRadius: '4px',
                                        padding: '4px 14px'
                                    }}
                                >
                                    LOG OUT
                                </Button>
                            </Box>
                            {/*profile */}
                            <Box sx={{ borderRadius: '7px', border: `1px solid ${lighten(extractedColor,0.5)}`, mt: '5px', display: 'flex', justifyContent: 'space-around', padding: '25px 20px 10px 10px' }}>
                                <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '7.2px', borderRadius: '7px', paddingRight: '20px',height:'35px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '7px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>Name</Typography>
                                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>Jorge Schinner</Typography>

                                </Box>
                                <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '7.2px', borderRadius: '7px', paddingRight: '20px',height:'35px' }}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '7px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>Email</Typography>
                                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>jorgeschinner@example.com</Typography>

                                </Box>
                                <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, padding: '7.2px', borderRadius: '7px', paddingRight: '20px' ,height:'35px'}}>
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '7px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>Phone</Typography>
                                    <Typography sx={{ fontWeight: '500', color: '#000', fontSize: '9px', fontFamily: '"Inter", sans-serif', lineHeight: '10px' }}>+1 9876541230</Typography>

                                </Box>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '40px',
                                        right: '15px',
                                        display: 'flex',
                                        alignItems: 'center'

                                    }}
                                >
                                    <Icon icon="material-symbols:edit" width="12px" height="12px" style={{ color: '#000000' }} />
                                    <Typography sx={{ fontWeight: '400', color: '#000', fontSize: '8px', fontFamily: '"Inter", sans-serif',mt:'1px' }}>Edit</Typography>
                                </Box>
                                <Avatar
                                    alt="Jorge Schinner"
                                    src= "./assets/images/ProfileImage.jpeg"
                                    sx={{ width: 50, height: 50, position: 'absolute', top: '0px', left: '20px', border: `0.5px solid ${extractedColor}`, }} />

                            </Box>
                            <Box sx={{ borderRadius: '7px', border: `1px solid ${lighten(extractedColor,0.5)}`, mt: '5px', padding: '5px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '8px', width: '100%' }}>
                                    <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#000000', fontFamily: '"Inter", sans-serif' }}>My addresses</Typography>
                                    <Typography sx={{ fontWeight: '500', fontSize: '10px', color: extractedColor, fontFamily: '"Inter", sans-serif', color: `${extractedColor}` }}>Add new Address</Typography>
                                </Box>


                                <Grid container sx={{gap:'8px',paddingBottom:'8px'}} >
                                    <Grid xs={5.8}>
                                        <Box sx={{ border: `1px solid ${extractedColor}`, borderRadius: '16px', padding: '8px', mt: '3px' }} >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif',fontWeight:'500',mr:'2px',color:"#000000" }}>Jorge Schinner</Typography>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif',mr:'2px',color:"#000000" }}>(+1 9876541230)</Typography>

                                                <Box sx={{ backgroundColor:lighten(extractedColor,0.5), borderRadius: '8px', padding: '3px 8px',mr:'0px' }} >
                                                    <Typography sx={{ fontSize: '8px', color: '#000000', fontFamily: '"Inter", sans-serif', }}>Home</Typography>
                                                </Box>
                                                <MoreVertIcon fontSize="9px" />

                                            </Box>
                                            <Typography sx={{ fontSize: '8px', padding: '3px 5px', fontFamily: '"Inter", sans-serif', }}> 12345 West Elm Street, Suite 9876, Floor 12, Downtown Business Center,
                                                Landmark Tower, Metropolis City, District 5, New York, NY 10001-5678,
                                                USA</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={5.8}>
                                        <Box sx={{ border: `1px solid ${extractedColor}`, borderRadius: '16px', padding: '8px', mt: '3px' }} >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif',mr:'2px',color:"#000000"  }}>Jorge Schinner</Typography>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>(+1 9876541230)</Typography>

                                                <Box sx={{ backgroundColor:lighten(extractedColor,0.5), borderRadius: '8px', padding: '3px 8px' }} >
                                                    <Typography sx={{ fontSize: '8px', color: '#000000', fontFamily: '"Inter", sans-serif', }}>Office</Typography>
                                                </Box>
                                                <MoreVertIcon fontSize="9px" />

                                            </Box>
                                            <Typography sx={{ fontSize: '8px', padding: '3px 5px', fontFamily: '"Inter", sans-serif', }}> 12345 West Elm Street, Suite 9876, Floor 12, Downtown Business Center,
                                                Landmark Tower, Metropolis City, District 5, New York, NY 10001-5678,
                                                USA</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={5.8}>
                                        <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '16px', padding: '8px', mt: '3px' }} >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif',mr:'2px',color:"#000000"  }}>Jorge Schinner</Typography>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>(+1 9876541230)</Typography>

                                                <Box sx={{ backgroundColor:lighten(extractedColor,0.5), borderRadius: '8px', padding: '3px 8px' }} >
                                                    <Typography sx={{ fontSize: '8px', color: '#000000', fontFamily: '"Inter", sans-serif', }}>Other</Typography>
                                                </Box>
                                                <MoreVertIcon fontSize="9px" />

                                            </Box>
                                            <Typography sx={{ fontSize: '8px', padding: '3px 5px', fontFamily: '"Inter", sans-serif', }}> 12345 West Elm Street, Suite 9876, Floor 12, Downtown Business Center,
                                                Landmark Tower, Metropolis City, District 5, New York, NY 10001-5678,
                                                USA</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={5.8}>
                                        <Box sx={{ border: `1px solid ${lighten(extractedColor,0.5)}`, borderRadius: '16px', padding: '8px', mt: '3px' }} >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif',mr:'2px',color:"#000000"  }}>Jorge Schinner</Typography>
                                                <Typography sx={{ fontSize: '8px', fontFamily: '"Inter", sans-serif', }}>(+1 9876541230)</Typography>

                                                <Box sx={{backgroundColor:lighten(extractedColor,0.5), borderRadius: '8px', padding: '3px 8px' }} >
                                                    <Typography sx={{ fontSize: '8px', color: '#000000', fontFamily: '"Inter", sans-serif', }}>Other</Typography>
                                                </Box>
                                                <MoreVertIcon fontSize="9px" />

                                            </Box>
                                            <Typography sx={{ fontSize: '8px', padding: '3px 5px', fontFamily: '"Inter", sans-serif', }}> 12345 West Elm Street, Suite 9876, Floor 12, Downtown Business Center,
                                                Landmark Tower, Metropolis City, District 5, New York, NY 10001-5678,
                                                USA</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>




                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProfileScreen
