import * as types from "../../actions/actionTypes";

// Define the initial state for email signup
const initialState = {
  createEmailSignupDetails: {
    users: [],  // This can be adjusted based on your requirements
    token: null, // This may not be used if you don't handle tokens in signup
    loading: false,
    error: null,
  },
};

// Define the emailSignupReducer function
const emailSignupReducer = (state = initialState.createEmailSignupDetails, action) => {
  switch (action.type) {
    case types.EMAIL_SIGNUP_START:
      // Start the loading state when the signup process begins
      return {
        ...state,
        loading: true,
      };
    case types.EMAIL_SIGNUP_SUCCESS:
      // Handle successful signup
      return {
        ...state,
        loading: false,
        // Update the state with the successful result if needed
        // e.g., users: [action.payload], or whatever data is relevant
      };
    case types.EMAIL_SIGNUP_ERROR:
      // Handle errors that occur during signup
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default emailSignupReducer;
