import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";

// Keyframe animations
const moveBlob = keyframes`
  0% {
    transform: translateX(0) translateY(0);
  }
  50% {
    transform: translateX(30px) translateY(-30px);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const floatBox1 = keyframes`
  0% {
    transform: translateY(-300%);
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translateY(300%);
    opacity: 0.3;
  }
`;

const floatBox2 = keyframes`
  0% {
    transform: translateY(-250%);
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(250%);
    opacity: 0.2;
  }
`;

const floatBox3 = keyframes`
  0% {
    transform: translateY(-400%);
    opacity: 0.4;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: translateY(400%);
    opacity: 0.4;
  }
`;

const floatBox4 = keyframes`
  0% {
    transform: translateY(-350%);
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(350%);
    opacity: 0.2;
  }
`;

const textBounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const textFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

// Styled components
// const LoaderContainer = styled(Box)(({ theme }) => ({
//   position: "relative",
//   width: "100%",
//   height: "100vh",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "#fdf6f3",
//   overflow: "hidden",
// }));

// Other styled components remain the same...

const Loader = ({ data }) => {
  console.log(data);
  const LoaderText = styled(Typography)(({ theme }) => ({
    position: "absolute",
    top: "58%",
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    zIndex: 3,
    fontFamily: '"Exo 2", sans-serif',
    color: theme.palette.text.primary,
    animation: `${textFadeIn} 2s ease-in, ${textBounce} 3s infinite ease-in-out`,
  }));
  // Styled components
  const LoaderContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fdf6f3",
    overflow: "hidden",
  }));

  const BackgroundBlob = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "150px",
    height: "300px",
    background: "rgba(128, 0, 255, 0.4)",
    filter: "blur(80px)",
    borderRadius: "30%",
    animation: `${moveBlob} 6s infinite ease-in-out`,
  }));

  const CircularLoader = styled(CircularProgress)(({ theme }) => ({
    position: "relative",
    zIndex: 2,
    color: "#8A2BE2",
    animation: `${rotate} 4s linear infinite`,
    "& circle": {
      strokeLinecap: "round",
    },
  }));

  // const LoaderText = styled(Typography)(({ theme }) => ({
  //   position: "absolute",
  //   top: "70%",
  //   fontWeight: 600,
  //   fontSize: "1.5rem",
  //   textAlign: "center",
  //   zIndex: 3,
  //   color: theme.palette.text.primary,
  // }));

  // Multiple Floating Boxes
  const FloatingBox1 = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "60px",
    height: "120px",
    // background: "#8A2BE2",
    background: "#8A2BE2",
    borderRadius: "10px",
    zIndex: 1, // Adjusted z-index
    opacity: 0.3,
    animation: `${floatBox1} 10s infinite ease-in-out`,
    animationTimingFunction: "linear",
    top: "10%",
    left: "10%",
  }));

  const FloatingBox2 = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "40px",
    height: "80px",
    background: "#8A2BE2",
    borderRadius: "10px",
    zIndex: 1, // Adjusted z-index
    opacity: 0.3,
    animation: `${floatBox2} 12s infinite ease-in-out`,
    animationTimingFunction: "linear",
    top: "20%",
    left: "30%",
  }));

  const FloatingBox3 = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "50px",
    height: "100px",
    background: "#8A2BE2",
    borderRadius: "10px",
    zIndex: 1, // Adjusted z-index
    opacity: 0.4,
    animation: `${floatBox3} 15s infinite ease-in-out`,
    animationTimingFunction: "linear",
    top: "5%",
    right: "20%",
  }));

  const FloatingBox4 = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "30px",
    height: "70px",
    background: "#8A2BE2",
    borderRadius: "10px",
    zIndex: 1, // Adjusted z-index
    opacity: 0.2,
    animation: `${floatBox4} 18s infinite ease-in-out`,
    animationTimingFunction: "linear",
    top: "15%",
    right: "5%",
  }));
  return (
    <>
      <LoaderContainer>
        {/* Background Blobs */}
        <BackgroundBlob sx={{ top: "20%", left: "10%" }} />
        <BackgroundBlob sx={{ bottom: "20%", right: "10%" }} />
        {/* Multiple Floating Boxes */}
        <FloatingBox1 />
        <FloatingBox2 />
        <FloatingBox3 />
        <FloatingBox4 />
        {/* Circular Loader */}
        <CircularLoader size={60} thickness={5} />
        {/* Text */}
        <LoaderText>
          <Typography
            sx={{
              display: "inline-flex",
              fontFamily: '"Exo 2", sans-serif',
              fontSize: "1.7rem",
              fontWeight: 600,
              lineHeight: "2.5rem",
              color: "#14042C",
            }}
          >
            {data.appName}
          </Typography>{" "}
          is being ready, please wait...
        </LoaderText>
      </LoaderContainer>
    </>
  );
};

export default Loader;
