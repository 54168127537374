import * as types from "../../actions/actionTypes";

const initialState = {
  updateTaxDetils: {
    data: [],
    token: null,
    loading: false,
  },
};

const updateTaxdetailsReducer = (
  state = initialState.updateTaxDetils,
  action
) => {
  switch (action.type) {
    case types.UPDATE_TAXDETAILS_START:
      // console.log('Loading started', state);
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_TAXDETAILS_SUCCESS:
      // console.log('Loading success', action.payload);
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.UPDATE_TAXDETAILS_ERROR:
      console.error('Loading error', action.payload); 
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateTaxdetailsReducer;
