import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const OuterBox = styled(Box)({
  position: "relative",
  borderRadius: "24px", // Consistent border radius
  padding: "3px", // Adjust this based on the border width
  background: `linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%), 
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  )`,
  backgroundSize: "300% 300%",
  backgroundPosition: "0 50%",
  animation: "moveGradient 4s alternate infinite",
  "@keyframes moveGradient": {
    "50%": {
      backgroundPosition: "100% 50%",
    },
  },
});

const InnerBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexWrap: 'nowrap',
  // gap: { xs: "8px", sm: "16px" },
  borderRadius: "21px", // Inner box radius, slightly smaller
  background: "#fff", // Main background color for the content
  // border: "1px solid #000",
  width: "100%", // Ensures full width within the outer box
  height: "100%", // Ensures full height within the outer box
});

const GradientBorderBox = (props) => {
  return (
    <OuterBox sx={props.sx}>
      <InnerBox>{props.children}</InnerBox>
    </OuterBox>
  );
};

export default GradientBorderBox;
